import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DurationPeriod } from 'src/app/shared/constants/duration-period-type.enum';
import { TextInputType } from 'src/app/shared/constants/text-input-type.enum';

@Component({
  selector: 'app-age-input',
  templateUrl: './age-input.component.html',
  styleUrls: ['./age-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AgeInputComponent),
      multi: true,
    },
  ],
})
export class AgeInputComponent implements ControlValueAccessor{

  @Input() label = 'Age';
  @Input() placeholder:string='';
  @Input() bgClass:string='bg-white'
  @Input() hasError:boolean = false;
  @Input() readOnly:boolean = false;


  textType = TextInputType;
  periodOptions = Object.keys(DurationPeriod).map((type) => ({
    label: type.replace(/_/g, ' '),
    value: type,
  }));
  hide:boolean=true;
  inputForm = new FormGroup({
    age: new FormControl <number|undefined>(undefined),
    period: new FormControl <string>(DurationPeriod.YEAR),
  })

  propagateChange = (_: any) => {};
  touchChange = (_: any) => {};

  onInputValueChange(){
    this.propagateChange(this.inputForm.value);
  }

  writeValue(value: any): void {
    if(!!value){
      this.inputForm.setValue(value);
      return;
    }
    this.inputForm.controls.period.setValue(DurationPeriod.YEAR)
  }

  registerOnChange(fn: any): void {
    this.propagateChange=fn;
  }

  registerOnTouched(fn: any): void {
    this.touchChange=fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    // throw new Error('Method not implemented.');
  }

}
