export const APP_CONST = {
  PER_PAGE: 10,
  PAGE_SIZE_OPTIONS: [10, 25, 50, 100],
  REQUEST_METHOD_LIST: [
    {
      label: 'GET',
      value: 'get',
    },
    {
      label: 'POST',
      value: 'post',
    },
    {
      label: 'PUT',
      value: 'put',
    },
    {
      label: 'PATCH',
      value: 'patch',
    },
    {
      label: 'DELETE',
      value: 'delete',
    },
  ],
};
