import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonComponent } from './components/button/button.component';
import { AutoCompleteInputComponent } from './components/form-controls/auto-complete-input/auto-complete-input.component';
import { ChipsInputComponent } from './components/form-controls/chips-input/chips-input.component';
import { MultiFieldInputComponent } from './components/form-controls/multi-field-input/multi-field-input.component';
import { OtpInputComponent } from './components/form-controls/otp-input/otp-input.component';
import { SelectInputComponent } from './components/form-controls/select-input/select-input.component';
import { TextInputComponent } from './components/form-controls/text-input/text-input.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { DateInputComponent } from './components/form-controls/date-input/date-input.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { PageTitleComponent } from './components/page-title/page-title.component';
import { DragDropDirective } from './directives/drag-drop.directive';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { CapitalizePipe } from './pipes/capitalize.pipe';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { AuthorizationDirective } from './directives/authorization.directive';
import { IsRootAdminDirective } from './directives/isRootAdmin.directive';
import { AgeInputComponent } from './components/form-controls/age-input/age-input.component';
import { SearchHighlightPipe } from './pipes/search-highlight.pipe';
import { DurationInputComponent } from './components/form-controls/duration-input/duration-input.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatTabsModule} from '@angular/material/tabs';
import {MatTableModule} from '@angular/material/table';




@NgModule({
  declarations: [
    ButtonComponent, AutoCompleteInputComponent, ChipsInputComponent, MultiFieldInputComponent,
    OtpInputComponent, SelectInputComponent, TextInputComponent, DateInputComponent, PageTitleComponent,
    DragDropDirective, SafeUrlPipe, CapitalizePipe, AuthorizationDirective, IsRootAdminDirective, AgeInputComponent,
    SearchHighlightPipe,
    DurationInputComponent
  ],
  imports: [
    CommonModule, MatAutocompleteModule, ReactiveFormsModule, MatInputModule, MatChipsModule, MatIconModule,
    MatSelectModule, MatButtonModule, MatDialogModule, MatDatepickerModule, MatNativeDateModule, MatSlideToggleModule,
    MatCheckboxModule, MatTabsModule, MatTableModule
  ],
  exports:[
    ButtonComponent, AutoCompleteInputComponent, ChipsInputComponent, MultiFieldInputComponent,
    OtpInputComponent, SelectInputComponent, TextInputComponent, DateInputComponent, PageTitleComponent,
    DragDropDirective, SafeUrlPipe, CapitalizePipe, MatSlideToggleModule, AuthorizationDirective, IsRootAdminDirective,
    AgeInputComponent, SearchHighlightPipe, DurationInputComponent, MatCheckboxModule, MatTabsModule, MatTableModule
  ],
})
export class SharedModule { }
