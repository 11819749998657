import { inject, signal } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { TextInputType } from "src/app/shared/constants/text-input-type.enum";
import { BaseComponent } from "./base-component.class";

export abstract class BaseFormComponent extends BaseComponent{

  textInputType = TextInputType;

  protected fb = inject(FormBuilder);
  protected isSubmitted = signal<boolean>(false);

  protected checkErrorMessage(form:FormGroup, control: string, isSubmitted:boolean): any {
    return (
      (form.get(control)?.invalid &&
        form.get(control)?.touched) ||
      (form.get(control)?.invalid && isSubmitted)
    );
  }

}
