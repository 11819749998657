export const RequestPath = {
  //Auth Module
  signin: 'auth/signin',
  signup: 'auth/sign-up',
  validateEmailOtp: 'auth/validate-email-otp',
  resendOtp: 'auth/resend-email-otp',
  forgotPassword: 'auth/forgot-password',
  resetPassword: 'auth/reset-password',
  socialSignUp: 'auth/sign-up-by-gmail',
  socialSignIn: 'auth/sign-in-by-gmail',


  //Old-record module
  getOldReports: 'medical-record/find-all-and-count/',
  medicalRecord: 'medical-record/',
  getRecord: 'medical-record/get-by-admin/',
  sharedRecord: 'medical-record/public-view/',
  makeRecordPublic: 'medical-record/make-record-public/',

  //Laboratory module
  laboratoryFindAll: 'laboratory/find-all-and-count',
  findLaboratory: 'laboratory/find-one',
  findBranchById: 'laboratory-branch/find-one/',
  findPatientById: 'patient/find-one',
  patient: 'patient/',

  //Profile
  getProfile: 'user/user-profile-details/',
  updateProfile: 'user/update-profile',

  //Report Module
  getParameter: 'website/get-parameters/',
  generateReport: 'medical-record/generate-result/',
  updateReport: 'medical-record/update-result/',
  getDiseaseSymptom: 'website/get-symptoms-by-disease-ids',
  generateAiReport: 'medical-record/generate-ai-result/',
  fileUpload: 'medical-record/upload-report',
  findAllDisease: 'disease/find-all-and-count-by-admin',
  updateRecord: 'medical-record/',
  findSymptoms: 'symptom/find-all-and-count-by-admin',

  //Prescription
  uploadPrescription: 'prescription/upload',
  getPrescriptions: 'prescription/find-all-and-count/',
  downloadPrescription: 'prescription/download/',
  getPrescription: 'prescription/',
  deletePrescription: 'prescription/delete/',

  //Smart report
  getSmartReport: 'medical-record/smart-report/',
  getSmartReportPdf: 'medical-record/private-smart-report-pdf/',
  uploadReport: 'medical-record/upload-report',

  //VHS
  getVhsRecords: 'vhs/get-records',
  findVhsRecord: 'vhs/find-record-orderId',
  checkOrderIdExist: 'vhs/check-orderId-in-medical-record',
  findRecordsByPtId: 'vhs/find-records-by-ptId',
  findRecordsByOrderIds: 'vhs/find-records-by-order-ids',

  //Comparison
  analyzeReports: 'medical-record/analyze-reports',

  //extract text from docs
  extractText: 'medical-record/extract-text',

  //insurance fraud detection
  uploadDocs: 'insurance/upload-docs-segregation'

}
