import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HeaderComponent } from './components/header/header.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { RouterModule } from '@angular/router';
import {MatMenuModule} from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from '../shared/shared.module';




@NgModule({
  declarations: [
    HeaderComponent,SidebarComponent
  ],
  imports: [
    CommonModule, RouterModule, MatMenuModule, MatIconModule, SharedModule
  ],
  exports: [SidebarComponent, HeaderComponent]
})
export class LayoutModule { }
