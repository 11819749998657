export const RoutePath = {
  //Auth Module
  signin: '/auth/sign-in',
  signup: '/auth/sign-up',
  forgotPassword: '/auth/forgot-password',
  verifyOtp: '/auth/verify-otp/',
  verifyOtpExistingUser: '/auth/verify-otp/existing-user',
  verifyOtpNewUser: '/auth/verify-otp/new-user',
  resetPassword: '/auth/reset-password',
  verifyResponse: '/auth/verification-response',

  //Report Module
  report: '/report',
  uploadReport: '/report/upload-report',
  reportEdit: '/report/edit/',
  reportView: '/report/view/',
  reportLabPatient: '/report/lab',


  //Lab Module
  lab: '/laboratory',
  labPatientRecord: '/laboratory/patient-record/',
  addPatient: '/laboratory/add-patient',
  editPatient: 'laboratory/edit-patient',

  home: '/home',
  oldReports: '/old-reports',
  profile: '/user/profile',

  //Prescription
  prescriptionView: '/prescription/view',
  prescriptionList: '/prescription/list',

  //VHS
  vhsRecords: '/vhs/lab-records',
  vhsEdit: '/vhs/edit',
  vhsView: '/vhs/view',

  //document scanner
  documentScanner: '/document-scanner'
}
