import { Component, OnInit } from '@angular/core';
import { LoaderDto, LoaderService } from '../../services/loader.service';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  standalone: true,
  imports: [CommonModule, MatProgressSpinnerModule]
})
export class LoaderComponent implements OnInit{
  isLoading = true; // default loader is true
  isShowBackground = true; // loader background will be visible

  constructor(private loaderService: LoaderService){}

  ngOnInit(): void {
    this.initiateLoaderIntegration();
  }

  initiateLoaderIntegration(){
    this.loaderService.onLoaderStatusChanged.subscribe((loaderDto: LoaderDto)=>{
      // set time out added for fix ExpressionChangedAfterItHasBeenCheckedError
      setTimeout(()=>{
        this.isLoading = loaderDto.loaderStatus;
        this.isShowBackground = loaderDto.isShowBackground ?? false;
      }, 0)
    })
  }
}
