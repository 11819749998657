import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface LoaderDto{
    loaderStatus: boolean;
    isShowBackground?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private loader = new BehaviorSubject<LoaderDto>({loaderStatus: true, isShowBackground: true});
  private loaderCount = 1;  // app start with loader. so the count is 1
  private status = false;

  onLoaderStatusChanged = this.loader.asObservable();

  constructor() { }

  loaderStatus() {
    return this.status;
  }

  showLoader(isShowBackground=false) {
    this.loaderCount++;
    const isLoaderHidden = this.loader.value.loaderStatus === false;
    if (isLoaderHidden && this.loaderCount > 0) {
      this.status = true;
      this.loader.next({loaderStatus:this.status, isShowBackground});
    }
  }

  hideLoader() {
    this.decreaseLoaderCount();
    const isLoaderVisible = this.loader.value.loaderStatus === true;
    if (isLoaderVisible && this.loaderCount === 0) {
      this.status = false;
      this.loader.next({loaderStatus: this.status});
    }
  }

  decreaseLoaderCount() {
    this.loaderCount--;
    if (this.loaderCount < 0) {
      this.loaderCount = 0;
    }
  }
}
