import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SelectOption } from 'src/app/shared/models/select-option.model';

@Component({
  selector: 'app-date-input',
  templateUrl: './date-input.component.html',
  styleUrls: ['./date-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateInputComponent),
      multi: true,
    },
  ],
})
export class DateInputComponent implements ControlValueAccessor{

  todayDate= new Date();
  dateFormControl=new FormControl('');
  @Input() placeholder:string= '';
  @Input() label:string='';
  @Input() bgClass:string='bg-white';
  @Input() hasError:boolean = false;
  @Input() readOnly:boolean = false;

  propagateChange = (_: any) => {};
  touchChange = (_: any) => {};


  getValue(){
    this.propagateChange(this.dateFormControl.value);
  }

  writeValue(obj: any): void {
    if (!!obj){
      this.dateFormControl.setValue(obj);
      return;
      }
      this.dateFormControl.setValue('')
  }

  registerOnChange(fn: any): void {
    this.propagateChange=fn;
  }

  registerOnTouched(fn: any): void {
    this.touchChange=fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    //throw new Error('Method not implemented.');
  }

}

