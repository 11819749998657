<form [formGroup]="inputForm" class="relative w-full">
  <label [for]="placeholder"  class=" text-xs font-medium" *ngIf="label">{{label}}</label>
  <div [ngClass]="['border', 'border-[#e5e7eb]', 'text-black', 'text-sm', 'rounded-md', 'flex', 'flex-nowrap', 'overflow-hidden', 'input-wrapper', hasError ? 'error-outline':'']" >
      <input class="p-2 w-2/3 border-white border-r-2 flex justify-end gap-2 items-center bg-[#EFEFEF]" [ngClass]=" hasError ? 'error-border':''"
        formControlName="age" matInput placeholder="Age" name="age" type="number" min="1" max="150" [readonly]="readOnly" (input)="onInputValueChange()">

        <mat-select  formControlName="period" class="p-2 w-1/3 text-white text-center bg-[#EFEFEF]" (selectionChange)="onInputValueChange()">
          <mat-option *ngFor="let option of periodOptions" [value]="option.value">{{option.label}}</mat-option>
        </mat-select>
  </div>
  <p class="text-red-600 text-xs absolute error-text">
    <ng-content #content></ng-content>
  </p>
</form>
