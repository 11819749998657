<app-grid-layout-template backgroundImageUrl="/assets/images/drugs-bg.png">
  <div class="text-center text-white">
    <!-- <div>SIGN IN</div> -->
    <div class="text-4xl font-light">Forgot Password</div>
    <p class="mt-4 font-light">
      Enter your registered email for the verification process, we will send 4 digits code to your email.
    </p>
  </div>
  <form class="flex flex-col gap-4 mt-4 w-full mx-auto" [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()">
    <div class="flex justify-center flex-col items-start pb-5">
      <app-text-input class="w-full text-white" label="Email" placeholder="Enter Email"
        formControlName="email" [hasError]="checkErrorMessage(forgotPasswordForm,'email', isSubmitted())">
        <ng-container *ngIf="checkErrorMessage(forgotPasswordForm, 'email', isSubmitted())">
          <p *ngIf="formControl.email.errors!['required']">
            Email Id required.
          </p>
          <p *ngIf="formControl.email.errors!['email']">
            Invalid email id.
          </p>
        </ng-container>
      </app-text-input>
    </div>
    <footer>
      <button type="submit" class="rounded w-full flex justify-center items-center my-2 py-2 font-medium bg-primary_dark">
        <div class="flex-1 text-center text-white">CONTINUE</div>
      </button>

      <div class="flex justify-center items-center">
        <div class="flex-1 text-center text-white font-light text-xs my-2">
          <a [routerLink]="'/auth/sign-in'">Back to Login</a>
        </div>
      </div>
    </footer>

  </form>
</app-grid-layout-template>
