import { Injectable, inject } from "@angular/core";
import { LoggerService } from "./log.service";

@Injectable({providedIn: 'root'})
export class AccessService{

  userAccesses:number[] =[];

  checkAccess(access: number | number[]) {
    const isArray = Array.isArray(access);
    if (isArray)
      return access.some((accessId) => this.userAccesses.includes(accessId));
    return this.userAccesses.includes(access);
  }

  setUserAccesses(accesses:number[]){
    this.userAccesses = accesses;

    /// Workaround: initializing logger here. because log service needs access to enable/disable
    /// Actual todo: use subject to rigger an event on subscription of that event do necessary things
    const logger = inject(LoggerService);
    logger.initLogger();
  }

}
