import { Injectable, inject } from "@angular/core";
import { map, of } from "rxjs";
import { AppHttpService } from "./app-http.service";
import { Country } from "../interfaces/countries-list.interface";

@Injectable({providedIn:"root"})
export class AppUtitlityService{
    private httpService = inject(AppHttpService);

    getCountriesList(){
        const countries = localStorage.getItem('countries-list');
        if(countries){
            return of(JSON.parse(countries) as Country[]);
        }

        return this.httpService.get('assets/json/country-list.json').pipe(
            map(countries=>{
              localStorage.setItem('countries-list', JSON.stringify(countries));  
              return countries as Country[];
            })
        )
    }

}

