import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, debounceTime, distinctUntilChanged, filter, fromEvent, map, startWith, tap } from 'rxjs';
import { BaseComponent } from 'src/app/core/components/base-component.class';
import { SelectOption } from 'src/app/shared/models/select-option.model';


@Component({
  selector: 'app-auto-complete-input',
  templateUrl: './auto-complete-input.component.html',
  styleUrls: ['./auto-complete-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutoCompleteInputComponent),
      multi: true,
    },
  ],
})
export class AutoCompleteInputComponent extends BaseComponent implements OnInit, ControlValueAccessor, OnChanges, AfterViewInit{

    propagateChange = (_: any) => {};
    touchChange = (_: any) => {};

   @Input() options:SelectOption[]=[];
   @Input() placeholder:string='';
   @Input() label:string='';
   @Input() labelColor:string='black';
   @Input() required!:boolean;
   @Input() disabled!:boolean;
   @Input() type:string = 'search';
   @Input() key:string = 'name';
   @Input() bgClass:string='bg-white';
   @Input() hasError:boolean=false;
   @Input() readOnly:boolean=false;

   @ViewChild('searchKey') searchText!: ElementRef;
   @Output() onValueChange = new EventEmitter<boolean>();
   @Output() searchTerm = new EventEmitter<string>();



   acFormControl=new FormControl<SelectOption | undefined>(undefined);
   filteredOptions!: Observable<SelectOption[]>;

   ngOnInit(): void {
    this.setFilter();
   }

   ngAfterViewInit(): void {
    this.subscription.add( fromEvent(this.searchText.nativeElement,'keyup')
      .pipe(debounceTime(500), distinctUntilChanged(),
      tap((input:any) => {
        const ignoreKeyTypes = ['ArrowDown', 'ArrowUp', 'ArrowLeft', 'ArrowRight', 'Enter'];
        const isValidKey = !ignoreKeyTypes.includes(input.key);
        if(isValidKey) {
          const value=this.searchText.nativeElement.value;
          value ? this.searchTerm.emit(value): ''
        }
      })).subscribe()
    )}

   ngOnChanges(changes:SimpleChanges){
    if(changes['options']?.currentValue){
      this.setFilter();
    }
   }

   /**Custom form control config */
   writeValue(value: any): void {
    if (value){
        this.acFormControl.patchValue(value);
        return;
    }
    this.acFormControl.setValue(undefined)
  }

  registerOnChange(fn: any): void {
    this.propagateChange=fn;
  }
  registerOnTouched(fn: any): void {
    this.touchChange=fn;
  }
  setDisabledState(isDisabled: boolean): void {
  //throw new Error('Method not implemented.');
  }

  /**To display label value in form field */
  displayFn(selectedOpt: any): string {
    return selectedOpt && selectedOpt['name'] ? selectedOpt['name'] : selectedOpt;
  }

  setFilter(){
    this.filteredOptions = this.acFormControl.valueChanges.pipe(
      startWith(''),
      map((value:any) => {
        const label = typeof value === 'string' ? value : value?.label;
        return label ? this._filter(label as string) : this.options.slice();
      }),
    );
  }

   onSelectionChange(){
    const selectedOpt:SelectOption = this.acFormControl.value as SelectOption;
    this.propagateChange(selectedOpt);
    this.onValueChange.emit(true);
   }

   private _filter(key: string): SelectOption[] {
    const filterValue = key.toLowerCase();
    return this.options.filter(option => option['label'].toLowerCase().includes(filterValue));
  }


}
