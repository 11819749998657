import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject, Subscription, debounceTime, distinctUntilChanged } from 'rxjs';
import { TextInputType } from 'src/app/shared/constants/text-input-type.enum';

@Component({
  selector: 'app-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextInputComponent),
      multi: true,
    },
  ],
})
export class TextInputComponent implements ControlValueAccessor, OnInit, OnDestroy{

  protected subscription = new Subscription();

  @Input() placeholder:string='';
  @Input() name:string='';
  @Input() label:string='';
  @Input() type:TextInputType = TextInputType.TEXT;
  @Input() bgClass:string='bg-white'
  @Input() hasError:boolean = false;
  @Input() readOnly:boolean = false;
  @Input() debounceTimer:number = 300;
  @Output() onChange:EventEmitter<string> = new EventEmitter<string>();

  private inputSubject = new Subject<string>();


  textType = TextInputType;

  hide:boolean=true;
  inputFormControl = new FormControl();

  propagateChange = (_: any) => {};
  touchChange = (_: any) => {};

  ngOnInit(): void {
    this.subscription.add(
      this.inputSubject
      .pipe(
        debounceTime(this.debounceTimer), // Adjust the debounce time as per your requirement
        distinctUntilChanged()
      )
      .subscribe((value) => {
        this.propagateChange(this.inputFormControl.value);
        this.onChange.emit(this.inputFormControl.value);
      })
    )
  }

  onInputValueChange(event:Event){
    const value = (event.target as HTMLInputElement).value;
    this.inputSubject.next(value);
  }

  writeValue(value: any): void {
    if(!!value){
      this.inputFormControl.setValue(value);
      return;
    }
    this.inputFormControl.setValue('');
  }

  registerOnChange(fn: any): void {
    this.propagateChange=fn;
  }

  registerOnTouched(fn: any): void {
    this.touchChange=fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    // throw new Error('Method not implemented.');
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
