<div class="header mb-8 flex justify-between">
    <div>
      <button mat-icon-button class="hidden menu-btn" (click)="menuClick.emit(true)">
        <img src="/assets/images/menu.svg" alt="menu">
      </button>
    </div>
    <div class="flex gap-8">
      <!-- <img src="/assets/images/notification.svg" alt="" class="w-6 h-6 cursor-pointer"> -->
      <img src="/assets/images/help.svg" alt="" class="w-8 h-8 cursor-pointer black-to-gray" (click)="openUserGuide()">
      <div class="flex gap-4 cursor-pointer" [matMenuTriggerFor]="menu" aria-label="Profile with a menu">
        <ng-container *ngIf="!showInitial(); else initial">
          <img [src]="profileUrl()" alt="" class="h-7 rounded-full">
        </ng-container>
        <ng-template #initial>
          <div class="rounded-full h-7 w-7 bg-primary text-white flex justify-center items-center text-xl font-medium"> {{userName().charAt(0)|uppercase}}</div>
        </ng-template>
        <div class="text-sm text-secondary flex justify-center items-center">{{userName() || "User name"}}</div>
        <img src="/assets/images/arrow-down.svg" class="w-4" alt="">
      </div>
    </div>
</div>

<mat-menu #menu="matMenu">
  <button mat-menu-item [routerLink]="['/user/profile']">
    <mat-icon>
      <img src="/assets/images/landing-profile.svg">
    </mat-icon>
    <span>View Profile</span>
  </button>
  <button mat-menu-item (click)="onSignOut()">
    <mat-icon>
      <img class="color-change" src="/assets/images/landing-logout.svg">
    </mat-icon>
    <span>Logout</span>
  </button>
</mat-menu>

