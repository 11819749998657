<app-grid-layout-template [hideLogo]="true" backgroundImageUrl="/assets/images/drugs-bg.png">
  <div class="flex flex-col mt-4 w-full mx-auto">
    <div class="flex justify-center mb-4">
      <img src="/assets/images/success.svg" class="h-24 w-24">
    </div>
    <div class=" mt-4">
     <div class="justify-center text-white font-light text-center">
      <h2 class=" mb-4  text-2xl">Successfully</h2>
      <p class="text-white text-sm">{{content}}</p>
      <button class="rounded w-full flex justify-center items-center my-6 py-2 font-medium bg-white" >
        <div class="flex-1 text-center text-[#0F1761]" [routerLink]="routePath.signin">CONTINUE</div>
      </button>
     </div>
    </div>
  </div>
</app-grid-layout-template>
