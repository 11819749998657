import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, inject } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSortModule, MatSort } from '@angular/material/sort';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {MatPaginator, MatPaginatorModule} from '@angular/material/paginator';
import { FormsModule } from '@angular/forms';
import { APP_CONST } from 'src/app/core/constants/app.constant';
import { MatButtonModule } from '@angular/material/button';
import { TextInputType } from '../../constants/text-input-type.enum';
import { SharedModule } from '../../shared.module';
import { MatIconModule } from '@angular/material/icon';
import { TableColumn, TableButton } from '../../models/table.model';
import { MatMenuModule } from '@angular/material/menu';

@Component({
  selector: 'app-table',
  standalone: true,
  imports: [CommonModule, MatFormFieldModule, MatInputModule, MatTableModule, MatSortModule, MatPaginatorModule, FormsModule,MatButtonModule, SharedModule, MatIconModule, MatMenuModule],
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnChanges{
  textInputType = TextInputType;
  searchInput:string='';

  @Input() title!:string;
  @Input() placeholder!:string;
  @Input() type:TextInputType = TextInputType.SEARCH;

  @Input() columns: TableColumn[] = [];
  @Input() buttons: TableButton[] = [];
  @Input() dataList: any[] = [];
  @Input() totalRecords = 0;
  @Input() hidePagination: boolean = false;
  @Input() perPage = APP_CONST.PER_PAGE;
  @Input() currentPage = 0;
  @Input() displayBackButton:boolean = true;

  displayColumns: string[] = ['sno'];
  pageSizeOptions = APP_CONST.PAGE_SIZE_OPTIONS;
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  @Output() changePage: EventEmitter<any> = new EventEmitter();
  @Output() action: EventEmitter<any> = new EventEmitter();

  location = inject(Location);

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dataList']?.currentValue) {
      this.updateTable(changes['dataList'].currentValue);
    }

    if (changes['columns']?.currentValue) {
      const columnKeys = changes['columns'].currentValue.map(
        (col: TableColumn) => col.key
      );
      const cols = ['sno'];
      if (this.buttons.length) cols.push('action');
      cols.splice(1, 0, ...columnKeys);
      this.displayColumns = cols;
    }
  }

  ngAfterViewInit() {
    this.resetSort();
  }

  updateTable(list: any[]) {
    this.dataSource.data = list;
    this.resetSort();
  }

  resetSort() {
    this.dataSource.sort = this.sort;
    this.totalRecords = this.totalRecords;
  }

  onPageChange(event: any): void {
     this.perPage = event.pageSize;
     this.currentPage = event.pageIndex;
    this.changePage.emit(event);
  }

  onClickAction(type: any, data: number, index: number) {
    this.action.emit({
      type,
      data,
      index,
    });
  }

}
