import { Injectable, inject } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, catchError, map, of, throwError } from 'rxjs';
import { AuthService } from 'src/app/auth/services/auth.service';
import { Router } from '@angular/router';
import { NotificationService } from './notification.service';
import { MessageType } from 'src/app/shared/models/message-type.enum';

@Injectable()
export class AppInterceptor implements HttpInterceptor {

  private authService = inject(AuthService);
  private router = inject(Router);
  private notification = inject(NotificationService);

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const token = this.authService.getAccessToken();
    const modifiedRequest = request.clone({
      headers: request.headers.append('Authorization', token),
    });
    return next.handle(modifiedRequest).pipe(catchError((x) => {
      return this.handleError(x);
    }), map((response) => {
      return response;
    }));
  }

  private handleError(err: HttpErrorResponse): Observable<any> {
    if (err.status === 401) {
      this.authService.signOut();
      this.router.navigate(['/auth/login']);
      return of(err.message);
    }
    this.notification.notify("Something went wrong!", MessageType.ERROR);
    return throwError(() => new Error(err.message))
  }
}
