import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from './core/guards/auth.guard';
import { UserResolver } from './core/services/user.resolver';
import { HomeComponent } from './home/components/home/home.component';
import { LayoutComponent } from './layout/components/layout/layout.component';
import { UploadReportComponent } from './report/components/upload-report/upload-report.component';
import { hasUnsavedChangesGuard } from './shared/services/unsaved-changes.guard';

const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [authGuard],
    resolve: { user: UserResolver },
  },
  {
    path:'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path:'public',
    loadChildren: () => import('./public/public.module').then((m) => m.PublicModule),
  },
  {
    path: 'smart-report',
    loadChildren: ()=> import('./smart-report/smart-report.module').then((m) => m.SmartReportModule)
  },
  {
    path: 'smart-report-v2',
    loadChildren: ()=> import('./smart-report-v2/smart-report-v2.module').then((m) => m.SmartReportV2Module)
  },
  {
    path: 'smart-report/:id',
    resolve: { user: UserResolver },
    loadChildren: ()=> import('./smart-report/smart-report.module').then((m) => m.SmartReportModule)
  },
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full'
  },
  {
    path:'',
    component: LayoutComponent,
    canActivate: [authGuard],
    resolve: { user: UserResolver },
    children:[
      {
        path: 'report',
        loadChildren: () => import('./report/report.module').then((m) => m.ReportModule),
      },
      {
        path: 'upload-report',
        component: UploadReportComponent,
        canDeactivate: [hasUnsavedChangesGuard]
      },
      {
        path: 'laboratory',
        loadChildren: () => import('./lab/lab.module').then((m) => m.LabModule),
      },
      {
        path: 'old-reports',
        loadChildren: () => import('./old-report/old-report.module').then((m) => m.OldReportModule),
      },
      {
        path: 'prescription',
        loadChildren: () => import('./prescription/prescription.module').then((m) => m.PrescriptionModule),
      },
      {
        path: 'chart',
        loadChildren: () => import('./charts/charts.module').then((m) => m.ChartsModule),
      },
      {
        path: 'comparison',
        loadChildren: () => import('./comparison/comparison.module').then((m) => m.ComparisonModule),
      },
      {
        path: 'user',
        loadChildren: () => import('./user/user.module').then((m) => m.UserModule),
      },
      {
        path: 'vhs',
        loadChildren: () => import('./vhs/vhs.module').then((m) => m.VhsModule),
      },
      {
        path: 'document-scanner',
        loadChildren: () => import('./document-scanner/document-scanner.module').then((m) => m.DocumentScannerModule),
      },
      {
        path: 'insurance',
        loadChildren: () => import('./insurance/insurance.module').then((m) => m.InsuranceModule),
      },
      {
        path:'',
        redirectTo:'report',
        pathMatch:'full'
      }
    ]
  },
  {
    path: '**',
    redirectTo:'auth'
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes,  {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
