<div class="register-container">
  <app-grid-layout-template [backgroundImageUrl]="bgImageUrl">
    <div class="text-center text-white">
      <!-- <div>SIGN UP</div> -->
      <div class="text-4xl font-bold">Create an Account</div>
      <div class="font-light text-sm">Welcome to Algohealth.</div>
    </div>
    <div class="flex border border-b-1 border-[#5882C1]"></div>
    <form class="flex flex-col mt-4 w-full mx-auto" [formGroup]="signupForm" (ngSubmit)="onSubmit()">
      <div class="grid grid-cols-2 gap-2">
        <div class="flex justify-center flex-col items-start relative pb-5">
          <app-text-input class="w-full text-white" name="firstName" [type]="textInputType.TEXT" label="First Name"
            placeholder="First Name" formControlName="firstName" [hasError]="checkErrorMessage(signupForm, 'firstName', isSubmitted())">
            <ng-container
            *ngIf="checkErrorMessage(signupForm, 'firstName', isSubmitted())">
            <p *ngIf="formControl.firstName.errors!['required']">
              First name required.
            </p>
            <p *ngIf="formControl.firstName.errors!['pattern']">
              Invalid Name.
            </p>
          </ng-container>
          </app-text-input>
        </div>
        <div class="flex justify-center flex-col items-start relative pb-5">
          <app-text-input class="w-full text-white" name="lastName" [type]="textInputType.TEXT" label="Last Name"
            placeholder="Last Name" formControlName="lastName" [hasError]="checkErrorMessage(signupForm, 'lastName', isSubmitted())">
            <ng-container
            *ngIf="checkErrorMessage(signupForm, 'lastName', isSubmitted())">
            <p *ngIf="formControl.lastName.errors!['required']">
              Last name required.
            </p>
            <p *ngIf="formControl.lastName.errors!['pattern']">
              Invalid Name.
            </p>
          </ng-container>
          </app-text-input>
        </div>
      </div>
      <div class="flex justify-center flex-col items-start relative pb-5">
        <app-date-input class="w-full text-white"  label="Date of Birth" placeholder="DOB" formControlName="dob">
          <ng-container
          *ngIf="checkErrorMessage(signupForm, 'dob', isSubmitted())">
          <p *ngIf="formControl['dob'].errors!['required']">
            Dob required.
          </p>
        </ng-container>
        </app-date-input>
      </div>
      <div class="flex justify-center flex-col items-start relative pb-5">
        <app-auto-complete-input class="w-full text-white" label="Country" placeholder="Country" formControlName="countryCode" [options]="countryOptions" [hasError]="checkErrorMessage(signupForm, 'countryCode', isSubmitted())">
          <ng-container
          *ngIf="checkErrorMessage(signupForm, 'countryCode', isSubmitted())">
          <p *ngIf="formControl['countryCode'].errors!['required']">
            Country required.
          </p>
        </ng-container>

        </app-auto-complete-input>
      </div>
      <div class="flex justify-center flex-col items-start relative pb-5">
        <app-text-input class="w-full text-white" name="email" [type]="textInputType.EMAIL" label="Email"
          placeholder="Email" formControlName="email" [hasError]="checkErrorMessage(signupForm, 'email', isSubmitted())">
          <ng-container *ngIf="checkErrorMessage(signupForm, 'email', isSubmitted())">
            <p *ngIf="formControl.email.errors!['required']">
              Email Id required.
            </p>
            <p *ngIf="formControl.email.errors!['pattern']">
              Invalid email id.
            </p>
          </ng-container>
        </app-text-input>
      </div>
      <div class="flex justify-center flex-col items-start relative pb-5">
        <app-text-input class="w-full text-white" name="password" label="Password" placeholder="Password"
          [type]="textInputType.PASSWORD" formControlName="password" [hasError]="checkErrorMessage(signupForm, 'password', isSubmitted())">
          <ng-container *ngIf="checkErrorMessage(signupForm, 'password', isSubmitted())">
          <p *ngIf="formControl.password.errors!['required']">
            Password required.
          </p>
          <p *ngIf="formControl.password.errors!['pattern']">
            Password should contain at least six character and includes a number, special character, small and capital letters.
          </p>
        </ng-container>
        </app-text-input>
      </div>
      <div class="flex justify-center items-center mb-1">
        <div class="flex-1 text-center text-white font-light text-xs">
          <section>
            <mat-checkbox (change)="this.agreeToConditions=!this.agreeToConditions">
              <span class="text-white">
                I have read and agree to
                <a class="font-medium hover:underline" [routerLink]="'/public/disclaimer'" target="_blank">Disclaimer</a> and <br>
                <a class="font-medium hover:underline" [routerLink]="'/public/terms-condition'" target="_blank">Terms of Service</a>
              </span>
            </mat-checkbox>
          </section>
        </div>
      </div>
      <button class="rounded w-full flex justify-center items-center my-2 mt-4 py-2 font-medium bg-primary_dark disabled:opacity-50 disabled:cursor-not-allowed" type="submit" [disabled]="!this.agreeToConditions">
        <div class="flex-1 text-center text-white">CREATE ACCOUNT</div>
      </button>
      <footer class="mt-2">
        <div class="flex justify-center items-center my-2">
          <div class="flex-1 text-center text-white font-light text-xs">Already have an account? <a class="font-medium"
              [routerLink]="'/auth/sign-in'">Sign In</a></div>
        </div>
        <div class="flex justify-center items-center mt-1">
          <asl-google-signin-button type='standard' size='medium' shape="rectangular"
            text="signup_with"></asl-google-signin-button>
        </div>

      </footer>

    </form>
  </app-grid-layout-template>
</div>
