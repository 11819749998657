import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchHighlight'
})
export class SearchHighlightPipe implements PipeTransform {

  transform(value: any, args: any): unknown {
      const re = new RegExp(args, 'igm');
     if(!!value){
      value= value.replace(re, '<span class="text-black font-bold">$&</span>');
     }
      return value;
  }

}
