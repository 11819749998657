import { Directive, ElementRef } from "@angular/core";
import { AuthService } from "src/app/auth/services/auth.service";

@Directive({
  selector: '[isRootAdmin]',
})
export class IsRootAdminDirective{

  constructor(
    private elementRef: ElementRef,
    private authService:AuthService
  ) {}

  ngOnInit(): void {
    const decodedToken = this.authService.getDecodeToken();
    const isRootAdmin = decodedToken && decodedToken.id == 1 && decodedToken.userName === 'rootadmin@cdss.com';
    if (!isRootAdmin) {
      this.elementRef.nativeElement.remove();
    }
  }

}
