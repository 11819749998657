import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, Input, SimpleChanges, ViewChild, forwardRef } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { SelectOption } from 'src/app/shared/models/select-option.model';



@Component({
  selector: 'app-chips-input',
  templateUrl: './chips-input.component.html',
  styleUrls: ['./chips-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ChipsInputComponent),
      multi: true,
    },
  ],
})
export class ChipsInputComponent {
  @Input() options: SelectOption[] = [];
  @Input() placeholder: string = "Select Value..";
  @Input() bgClass:string='bg-white';
  @Input() hasError:boolean = false;

  propagateChange = (_: any) => {};
  touchChange = (_: any) => {};
  chipFormControl = new FormControl();
  separatorKeysCodes: number[] = [ENTER, COMMA];
  filteredList: SelectOption[] = [];
  selectedList: SelectOption[] = [];

  value: any[] = [];
  @ViewChild('chipInput') chipInput: ElementRef<HTMLInputElement> | undefined;

  constructor() {
    this.chipFormControl.valueChanges.subscribe((inputValue) =>
      this.filter(inputValue ?? '')
    );
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes['options']?.currentValue) {
      this.filteredList = this.options = changes['options'].currentValue;
    }
  }

  writeValue(value: any) {
    if (value){
      this.selectedList = value;
      this.value = this.selectedList;
      this.filteredList = this.getFilteredOptions();
      return;
    }
   this.selectedList=[];
  }




  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.touchChange = fn;
  }

  onChangeValue(): void {
    this.value = this.selectedList;
    this.propagateChange(this.value);
    this.filteredList = this.getFilteredOptions();
  }

  add(event: MatChipInputEvent): void {
    let value: any = (event.value || '').trim();

    // Add our chip
    if (value) {
      value=this.options.find(option=>option.label==value);
      value?this.selectedList.push(value) : null;
      //this.selectedList.push(value);
    }
    this.onChangeValue();

    // Clear the input value
    this.chipInput!.nativeElement.value = '';
    //this.inputCtrl.setValue(null); bbb
    this.value=[];
  }

  remove(dataIndex: number): void {
    if (dataIndex >= 0) {
      this.selectedList.splice(dataIndex, 1);
      this.onChangeValue();
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.selectedList.push(event.option.value);
    this.chipInput!.nativeElement.value = '';
    //this.inputCtrl.setValue(null); bbb
    this.value=[];
    this.onChangeValue();
  }

  filter(inputValue: string): void {
    this.filteredList = this.options.filter(
      (option) =>
      option.label.toLowerCase().includes(inputValue)
    && !this.value.includes(option));

  }

  getFilteredOptions(){
    const selectedOptionIds = this.value.map(opt=>opt.value);
    const filteredList = this.options.filter(option=>!selectedOptionIds.includes(option.value));
    return filteredList;
  }
}
