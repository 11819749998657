import { Injectable, OnDestroy } from '@angular/core';
import { Subscription, fromEvent, merge, timer } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/services/auth.service';
import { DialogData } from 'src/app/shared/models/dialog.model';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class IdleLogoutService implements OnDestroy {
  private timeout = environment.idleLogoutTime ?? 10*60*1000; // 10 minutes in milliseconds
  private idle$!: Subscription;

  constructor(private authService: AuthService, private dialogService:DialogService) {
    this.setupIdleTimer();
  }

  setupIdleTimer() {
    // Listen to multiple user interaction events
    const events$ = merge(
      fromEvent(window, 'mousemove'),
      fromEvent(window, 'keydown'),
      fromEvent(window, 'scroll')
    ).pipe(tap(() => this.resetTimer()));

    // Subscribe to the event stream to reset the timer on interaction
    events$.subscribe();

    // Initialize the idle timer
    this.resetTimer();
  }

  private resetTimer() {
    // If there's an existing timer, clear it before setting a new one
    if (this.idle$) {
      this.idle$.unsubscribe();
    }

    // Set a new timer
    this.idle$ = timer(this.timeout).subscribe(() => {
      this.logoutUser();
    });
  }

  private async logoutUser() {
    const isLogin = await this.authService.verifyUserLogin()
    if (isLogin) {
      const data:DialogData = {
        title: "Logging Out",
        content: "The system has been idle for more than 10 minutes. It will automatically log out shortly.",
        button: {
          proceed: "Stay Logged in",
          discard: undefined,
          color: "primary"
        }
      }
      this.dialogService.openDialog(data, "240px").subscribe();
      setTimeout(() => {
        this.authService.signOut();
      }, 10000);
    }
  }

  ngOnDestroy() {
    if (this.idle$) {
      this.idle$.unsubscribe();
    }
  }
}
