import { Component, OnInit, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BaseFormComponent } from 'src/app/core/components/base-form.class';
import { ValidationPatterns } from 'src/app/core/utils/validation-patterns';
import { RoutePath } from 'src/app/core/constants/route.const';
import { MessageType } from 'src/app/shared/models/message-type.enum';
import { OtpPath } from '../../constants/otp-path.const';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-verify-otp',
  templateUrl: './verify-otp.component.html',
  styleUrls: ['./verify-otp.component.scss']
})
export class VerifyOtpComponent extends BaseFormComponent implements OnInit{

  timeLeft:number = 30;
  interval:any;

  otpForm = new FormGroup({
    otp: new FormControl('', [Validators.required, Validators.pattern(ValidationPatterns.otp)])
  })

  private activatedRoute = inject(ActivatedRoute);
  private authService = inject(AuthService);


  ngOnInit(): void {
    this.startTimer();
  }

  startTimer() {
    this.interval = setInterval(() => {
      if(this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.resetTimer();
      }
    },1000)
  }

  resetTimer() {
    clearInterval(this.interval);
    this.timeLeft=30
  }

  async resendOtp(){
    const isOtpSent = await this.authService.resendOtp({email:history.state.email});
    if(isOtpSent){
      this.startTimer();
    }
  }


  async onSubmit(){
    this.isSubmitted.set(true);
    if(this.otpForm.valid){
      const payload:{email:string, otp:number} ={email: history.state['email'], otp:Number(this.otpForm.value.otp)};
      const userType = this.activatedRoute.snapshot.paramMap.get('type');
      if(userType === OtpPath.NEW_USER){
        const response = await  this.authService.verifyOTP(payload);
        if (response.status) {
          this.router.navigateByUrl(RoutePath.verifyResponse, {state:{title:'Success!', content:'Your account has been created successfully'}});
        } else{
          this.notificationService.notify(response.message, MessageType.ERROR);
        }
      }else{
        this.router.navigateByUrl(RoutePath.resetPassword,{state:payload});
      }
    }
  }

}
