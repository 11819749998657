export enum OtpPath{
  NEW_USER = 'new-user',
  EXISTING_USER = 'existing-user'
}

export enum DataTableIcons{
  VIEW= `<svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.0342 13C12.691 13 14.0342 11.6569 14.0342 10C14.0342 8.34315 12.691 7 11.0342 7C9.37733 7 8.03418 8.34315 8.03418 10C8.03418 11.6569 9.37733 13 11.0342 13Z" stroke="#222222" stroke-width="1.2"/>
      <path d="M17.8237 9.31167C17.9641 9.59333 18.0342 9.73411 18.0342 10C18.0342 10.2659 17.9641 10.4067 17.8237 10.6883C17.1407 12.0592 15.1909 15 11.0342 15C6.87748 15 4.92772 12.0592 4.24463 10.6883C4.10433 10.4067 4.03418 10.2659 4.03418 10C4.03418 9.73411 4.10433 9.59333 4.24463 9.31167C4.92772 7.94078 6.87748 5 11.0342 5C15.1909 5 17.1407 7.94078 17.8237 9.31167Z" stroke="#222222" stroke-width="1.2"/>
      <path d="M17.0342 1H17.2342C19.0255 1 19.9212 1 20.4777 1.5565C21.0342 2.11299 21.0342 3.00866 21.0342 4.8V5" stroke="#222222" stroke-width="1.2" stroke-linecap="round"/>
      <path d="M17.0342 19H17.2342C19.0255 19 19.9212 19 20.4777 18.4435C21.0342 17.887 21.0342 16.9913 21.0342 15.2V15" stroke="#222222" stroke-width="1.2" stroke-linecap="round"/>
      <path d="M5.03418 1H4.83418C3.04284 1 2.14717 1 1.59068 1.5565C1.03418 2.11299 1.03418 3.00866 1.03418 4.8V5" stroke="#222222" stroke-width="1.2" stroke-linecap="round"/>
      <path d="M5.03418 19H4.83418C3.04284 19 2.14717 19 1.59068 18.4435C1.03418 17.887 1.03418 16.9913 1.03418 15.2V15" stroke="#222222" stroke-width="1.2" stroke-linecap="round"/>
      </svg>`,
  EDIT =`<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.2236 10.0059L14.2236 6.00586M18.2236 10.0059L21.2236 7.00586L17.2236 3.00586L14.2236 6.00586M18.2236 10.0059L17.2236 11.0059M14.2236 6.00586L8.22363 12.0059V16.0059H12.2236L14.7236 13.5059M20.2236 14.0059V20.0059H12.2236M10.2236 4.00586H4.22363V20.0059H7.22363" stroke="black" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>`,
  SHARE = `<?xml version="1.0" encoding="utf-8"?>
  <!-- Generator: Adobe Illustrator 26.5.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
     viewBox="0 0 20 19" style="enable-background:new 0 0 20 19;" xml:space="preserve">
  <style type="text/css">
    .st0{fill:none;stroke:#000000;stroke-miterlimit:10;}
  </style>
  <line class="st0" x1="7" y1="7.9" x2="13" y2="4.8"/>
  <line class="st0" x1="6.9" y1="11.3" x2="12.9" y2="14.3"/>
  <circle class="st0" cx="4.8" cy="9.7" r="2.9"/>
  <circle class="st0" cx="15.8" cy="15.4" r="2.9"/>
  <circle class="st0" cx="15.8" cy="3.5" r="2.9"/>
  </svg>`,
  DELETE =`<?xml version="1.0" encoding="utf-8"?>
  <!-- Generator: Adobe Illustrator 26.5.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
     viewBox="0 0 19 21" style="enable-background:new 0 0 19 21;" xml:space="preserve">
  <style type="text/css">
    .st0{fill:none;stroke:#000000;stroke-width:1.1;stroke-linecap:round;stroke-linejoin:round;}
  </style>
  <g id="Group_2264">
    <path id="Vector" class="st0" d="M7.6,10.5v5.1"/>
    <path id="Vector_2" class="st0" d="M11.9,10.5v5.1"/>
    <path id="Vector_3" class="st0" d="M1.2,5.4h17"/>
    <path id="Vector_4" class="st0" d="M3.4,8.5v8.2c0,1.7,1.4,3.1,3.2,3.1H13c1.8,0,3.2-1.4,3.2-3.1V8.5"/>
    <path id="Vector_5" class="st0" d="M6.5,3.3c0-1.1,1-2.1,2.1-2.1h2.1c1.2,0,2.1,0.9,2.1,2.1v2.1H6.5V3.3z"/>
  </g>
  </svg>`,
  DOWNLOAD = `<svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M1 12V13C1 15.2091 2.79086 17 5 17H17C19.2091 17 21 15.2091 21 13V12" stroke="black" stroke-width="1.5"/>
  <path d="M11 0V12" stroke="black" stroke-width="1.5"/>
  <path d="M5 8L9.45139 12.4514C10.283 13.283 11.6491 13.221 12.402 12.3175L16 8" stroke="black" stroke-width="1.5"/>
  </svg>`
}
