import { Component, inject } from '@angular/core';
import { BaseComponent } from './core/components/base-component.class';
import { LoggerService } from './core/services/log.service';
import { IdleLogoutService } from './core/services/idle-logout.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends BaseComponent{

  private logService = inject(LoggerService);
  private idleLogoutService = inject(IdleLogoutService);

  ngOnInit() {
    this.initLoggerAndHideLoader();
    this.idleLogoutService.setupIdleTimer();
  }

  initLoggerAndHideLoader(){
    this.loaderService.hideLoader();
  }
}
