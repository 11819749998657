<div class="flex justify-between align-center mb-8" *ngIf="placeholder || title">
  <div class="mt-8 flex items-center">
    <h2 class="text-xl font-medium">{{title}}</h2>
  </div>
  <div >
    <app-text-input *ngIf="placeholder" [type]="textInputType.SEARCH" class="w-3/12" [placeholder]="placeholder" [(ngModel)]="searchInput" [bgClass]="'bg-[#EFEFEF]'">
    </app-text-input>
  </div>
</div>

<div>
<div class="table-container">
  <table class="table " mat-table matSort [dataSource]="dataSource">
    <ng-container matColumnDef="sno">
        <th mat-header-cell *matHeaderCellDef> S.No </th>
        <td mat-cell *matCellDef="let row; let i = index"> {{i + (currentPage * perPage) + 1}} </td>
    </ng-container>

    <ng-container *ngFor="let col of columns" [matColumnDef]="col.key">
        <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header> {{col.label}} </th>
        <td class="text-center" mat-cell *matCellDef="let row">
          <ng-container *ngIf="col.key==='createdAt' || col.key==='updatedAt'; else normalField">{{row[col.key] | date:'mediumDate'}} {{row[col.key] | date:'shortTime'}}</ng-container>
          <ng-template #normalField>{{row[col.key]}}</ng-template>
        </td>
    </ng-container>

    <ng-container matColumnDef="action" *ngIf="buttons.length">
        <th mat-header-cell *matHeaderCellDef> Action </th>
        <td mat-cell *matCellDef="let row;let i = index">
            <div class="flex gap-2 justify-c items-center">
                <div class="buttons-container">
                  <button *ngFor="let button of buttons"  type="button" mat-icon-button
                (click)="onClickAction(button.name, row, i)" [innerHTML]="button.iconUrl" [title]="button.name">
                </button>
                </div>

                <button mat-icon-button [matMenuTriggerFor]="menu" id="menu-btn">
                  <mat-icon>more_horiz</mat-icon>
                </button>

                <mat-menu #menu="matMenu">
                  <ng-container *ngFor="let button of buttons">
                    <button  mat-menu-item type="button"
                    (click)="onClickAction(button.name, row, i)" [title]="button.name">
                    <div class="flex items-center gap-4">
                      <span class="h-4 w-5" [innerHTML]="button.iconUrl"></span>
                      <span>{{button.name | titlecase}}</span>
                    </div>
                  </button>
                  </ng-container>
                </mat-menu>

            </div>
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayColumns" class="bg-secondary"></tr>
    <tr mat-row *matRowDef="let row; columns: displayColumns;"
        [ngStyle]="{'backgroundColor': row.rowColor ? row.rowColor : 'inherit' }"></tr>
</table>
</div>
<div class="text-center font-medium my-3" *ngIf="!dataList?.length">
    No Data Found
</div>
<div *ngIf="!hidePagination" class="my-8">
    <mat-paginator [length]="totalRecords" [pageSizeOptions]="pageSizeOptions" [pageSize]="perPage"
        [pageIndex]="currentPage" (page)="onPageChange($event)"
        aria-label="Select page of user list">
    </mat-paginator>
</div>
</div>

