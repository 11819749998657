import { SocialAuthService } from '@abacritt/angularx-social-login';
import { Component, OnInit, inject, signal } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { BaseFormComponent } from 'src/app/core/components/base-form.class';
import { RoutePath } from 'src/app/core/constants/route.const';
import { ValidationPatterns } from 'src/app/core/utils/validation-patterns';
import { MessageType } from 'src/app/shared/models/message-type.enum';
import { environment } from 'src/environments/environment';
import { SignInMode } from '../../constants/signin-mode.enum';
import { SignIn } from '../../models/sign-in.model';
import { AuthService } from '../../services/auth.service';
import { SocialAuthenticationService } from '../../services/social-auth.service';
import { LoggerService } from 'src/app/core/services/log.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent extends BaseFormComponent implements OnInit {
  bgImageUrl: string = '/assets/images/landing-bg.png';
  validUser = signal(false);

  loginForm = new FormGroup({
    userName: new FormControl('', [Validators.required, Validators.pattern(ValidationPatterns.email)]),
    password: new FormControl('', Validators.required),
  });

  get formControl() {
    return this.loginForm.controls;
  }

  private socialAuthService = inject(SocialAuthService);
  private socialService = inject(SocialAuthenticationService);
  private authService = inject(AuthService);
  private recaptchaV3Service = inject(ReCaptchaV3Service);
  private logService = inject(LoggerService);


  async ngOnInit(){
    const isLoggedIn = await this.authService.verifyUserLogin();
    if(isLoggedIn){
      this.router.navigateByUrl(RoutePath.home);
    }
    this.onSocialLogin();
    this.initCaptcha();
  }

  initCaptcha(): void {
    if(environment.production){
      this.subscription.add(
        this.recaptchaV3Service.execute(environment.captchaSiteKey, 'homepage', (token) => {
          this.validUser.set(true);
        }, {
          useGlobalDomain: false,
          useEnterprise: false
        })
      )
       return;
    } this.validUser.set(true); //for dev mode
  }



  async onSubmit() {
    this.isSubmitted.set(true);
    if(!this.validUser()){
      this.notificationService.notify('Verification Failed', MessageType.ERROR);
      return;
    }
    if (this.loginForm.valid) {
      const payload: SignIn = this.loginForm.value as SignIn;
      const response = await this.authService.signIn(payload);
      if (response.status) {
        this.authService.setAccessToken(response.data!);
        this.notificationService.notify("Logged in successfully", MessageType.SUCCESS)
        this.router.navigateByUrl(RoutePath.home);
      }else{
        this.notificationService.notify(response.message, MessageType.ERROR)
      }
    }
  }

  onSocialLogin(){
    this.subscription.add(
      this.socialAuthService.authState.subscribe(async (user) => {
        const payload = {token: user.idToken, provider: user.provider.toUpperCase()};
          const response = await this.socialService.socialSignIn(payload);
          if(response.status){
            this.authService.setAccessToken(response.data!);
            localStorage.setItem("category", SignInMode.OAUTH)
            this.notificationService.notify("Logged in successfully", MessageType.SUCCESS);
            this.router.navigateByUrl(RoutePath.home);
          }else{
            this.notificationService.notify(response.message, MessageType.ERROR);
          }
      })
    )
  }
}
