import { Component, OnDestroy, inject } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject, Subscription, debounceTime } from "rxjs";
import { AuthService } from "src/app/auth/services/auth.service";
import { DialogService } from "src/app/shared/services/dialog.service";
import { RoutePath } from "../constants/route.const";
import { LoaderService } from "../services/loader.service";
import { NotificationService } from "../services/notification.service";
import { UiAccessList } from "../constants/ui-access-list.const";
import { environment } from "src/environments/environment";

@Component({
  selector: 'base',
  template: ''
})
export abstract class BaseComponent implements OnDestroy{

  isResponseReceived = false;

  protected router = inject(Router);
  protected dialogService = inject(DialogService);
  protected subscription = new Subscription();
  protected loaderService: LoaderService= inject(LoaderService);
  protected notificationService: NotificationService= inject(NotificationService);
  protected searchDebounceSubject = new Subject<any>();


  protected isInitialLogin = Boolean(localStorage.getItem('isInitialLogin')) || false;
 // protected forInsurence = environment.forInsurence;


  protected routePath = RoutePath;
  protected uiAccessList = UiAccessList;

  setupSearchDebounceCallback(callback: Function) {
    this.searchDebounceSubject
      .pipe(debounceTime(300)) // Adjust the debounce time (in milliseconds) as per your needs
      .subscribe((event) => {
        callback(event);
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
