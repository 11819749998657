import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { GoogleLoginProvider, GoogleSigninButtonModule, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxCaptchaModule } from 'ngx-captcha';
import { environment } from 'src/environments/environment';
import { GridLayoutTemplateComponent } from '../layout/components/grid-layout-template/grid-layout-template.component';
import { SharedModule } from '../shared/shared.module';
import { AuthRoutingModule } from './auth-routing.module';
import { AuthContainerComponent } from './components/auth-container/auth-container.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { VerifyOtpComponent } from './components/verify-otp/verify-otp.component';
import { VerifyResponseComponent } from './components/verify-response/verify-response.component';


@NgModule({
  declarations: [
    AuthContainerComponent,
    LoginComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    VerifyOtpComponent,
    VerifyResponseComponent
  ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    GridLayoutTemplateComponent, ReactiveFormsModule,
    SharedModule, GoogleSigninButtonModule,  NgxCaptchaModule
  ],
  providers: [
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              environment.googleClientId
            )
          },
        ],
        onError: (err) => {
          console.error(err);
        }
      } as SocialAuthServiceConfig,
    }
  ],
})
export class AuthModule { }
