import { SocialAuthService } from '@abacritt/angularx-social-login';
import { Injectable, inject, signal } from '@angular/core';
import { Router } from '@angular/router';
import jwtDecode from 'jwt-decode';
import { lastValueFrom } from 'rxjs';
import { RequestPath } from 'src/app/core/constants/request-path.const';
import { RoutePath } from 'src/app/core/constants/route.const';
import { ApiResponse } from 'src/app/core/interfaces/api-reponse.model';
import { BaseService } from 'src/app/core/services/base-service.class';
import { environment } from 'src/environments/environment';
import { SignInMode } from '../constants/signin-mode.enum';
import { AppToken } from '../models/app-token.model';
import { ResetPassword } from '../models/reset-password.model';
import { SignIn } from '../models/sign-in.model';
import { SignUp } from '../models/sign-up.model';
import { SignInResponse } from '../models/signin-response.model';
import { AuthModule } from '../auth.module';

@Injectable({ providedIn: AuthModule })
export class AuthService extends BaseService{

  private router:Router = inject(Router);
  private socialAuthService:SocialAuthService = inject(SocialAuthService);

  private endPoint = signal(environment.AUTH_URL)

  signIn(payload: SignIn): Promise<ApiResponse<SignInResponse>> {
    return lastValueFrom(this.httpService.post(this.endPoint()+RequestPath.signin, payload));
  }

  setAccessToken(body: SignInResponse){
  const tokenPayload = this.decodeToken(body.token);
  if(tokenPayload){
    localStorage.setItem("token", body.token)
    localStorage.setItem("profilePictureUrl", tokenPayload.profilePictureUrl);
    localStorage.setItem("name", tokenPayload.name);
    localStorage.setItem("exp", tokenPayload.exp.toString());
  }
  }

  getAccessToken(){
    const token = localStorage.getItem("token");
    const expTime = localStorage.getItem("exp");
    const validToken = (+expTime! > Math.floor(Date.now() / 1000)) && token;
    return validToken ?  token : "";
  }

  decodeToken(token:string) :AppToken{
    return jwtDecode(token);
  }

  getDecodeToken(){
    const token = this.getAccessToken();
    const decodedToken = this.decodeToken(token);
    return decodedToken;
  }

  async verifyUserLogin(){
    const isValidLogin = this.getAccessToken();
    return !!isValidLogin;
  }

  signOut(){
    if(localStorage.getItem('category') === SignInMode.OAUTH){
      this.socialAuthService.signOut();
    }
    const hasDisplayedUserGuide = localStorage.getItem('hasDisplayedUserGuide') || false;
    localStorage.clear();
    localStorage.setItem('hasDisplayedUserGuide', hasDisplayedUserGuide.toString());
    this.router.navigateByUrl(RoutePath.signin);

    /// Note: Reload is for getting latest code changes from the server, instead of asking user to refresh the page
    /// Logout will trigger automatically from the IdleLogoutService after 10 mins of inactivity
    /// So that user will automatically get the latest code at their next login
    window.location.reload();
  }

  signUp(payload: SignUp): Promise<ApiResponse<string>> {
    return lastValueFrom(this.httpService.post(this.endPoint()+RequestPath.signup, payload));
  }

  resendOtp(payload:{email:string}): Promise<ApiResponse<string>>{
    return lastValueFrom(this.httpService.post(this.endPoint()+RequestPath.resendOtp, payload));
  }

  verifyOtp(payload:Partial<ResetPassword>): Promise<ApiResponse<string>>{
    return lastValueFrom(this.httpService.post(this.endPoint()+RequestPath.validateEmailOtp, payload));
  }

  forgotPassword(payload: { email: string }): Promise<ApiResponse<string>> {
    return lastValueFrom(this.httpService.post(this.endPoint()+RequestPath.forgotPassword, payload));
  }

  verifyOTP(payload: Partial<ResetPassword>): Promise<ApiResponse<string>>{
    return lastValueFrom(this.httpService.post(this.endPoint()+RequestPath.validateEmailOtp, payload));
  }

  resetPassword(payload: ResetPassword): Promise<ApiResponse<string>>{
    return lastValueFrom(this.httpService.post(this.endPoint()+RequestPath.resetPassword, payload));
  }

}
