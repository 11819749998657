import { Injectable } from "@angular/core";
import { HttpClient, HttpContext, HttpErrorResponse, HttpEvent, HttpHeaders, HttpParams } from '@angular/common/http';
import { ApiOptions } from "../interfaces/api-options.interface";
import { Observable, catchError, finalize, throwError } from "rxjs";
import { LoaderService } from "./loader.service";


export type ResponseJson = {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    context?: HttpContext;
    observe?: any;
    params?: HttpParams | {
      [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
    };
    reportProgress?: boolean;
    responseType?: any;
    withCredentials?: boolean;
}

@Injectable({providedIn:"root"})
export class AppHttpService{

  constructor(
    private http: HttpClient,
    private loaderService: LoaderService
  ) { }

  private formatErrors(error: HttpErrorResponse) {
    return throwError(()=>new Error(error.message));
  }

  get<T>(url: string, options?: ResponseJson,
  appOptions:ApiOptions = {showLoader: true, showLoaderBackground: false}
  ): Observable<T> {
    if(appOptions.showLoader){
      this.showLoader(appOptions, url);
    }
    return this.http.get<T>(url, options).pipe(catchError(this.formatErrors), finalize(()=>this.hideLoader(appOptions, url)));
  }

  post<T>(url: string, body: any | null, options?: ResponseJson,
  appOptions:ApiOptions = {showLoader: true, showLoaderBackground: false}
  ): Observable<T> {
    if(appOptions.showLoader){
      this.showLoader(appOptions, url);
    }
    return this.http.post<T>(url, body, options).pipe(catchError(this.formatErrors), finalize(()=>this.hideLoader(appOptions, url)));
  }

  put<T>(url: string, body: any | null, options?: ResponseJson,
    appOptions:ApiOptions = {showLoader: true, showLoaderBackground: false}
    ): Observable<T> {
      if(appOptions.showLoader){
        this.showLoader(appOptions, url);
      }
      return this.http.put<T>(url, body, options).pipe(catchError(this.formatErrors), finalize(()=>this.hideLoader(appOptions, url)));
    }

  delete<T>(url: string, options?: {
    headers?: HttpHeaders | {
        [header: string]: string | string[];
    };
    context?: HttpContext;
    observe?: 'body';
    params?: HttpParams | {
        [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
    body?: any | null;
  },
  appOptions:ApiOptions = {showLoader: true, showLoaderBackground: false}
  ): Observable<T> {
    if(appOptions.showLoader){
      this.showLoader(appOptions, url);
    }
    return this.http.delete<T>(url, options).pipe(catchError(this.formatErrors), finalize(()=>this.hideLoader(appOptions, url)));
  }

  showLoader(apiOptions: ApiOptions, url: string){
    if(apiOptions.showLoader){
      this.loaderService.showLoader(apiOptions.showLoaderBackground);
    }
  }

  hideLoader(apiOptions: ApiOptions, url: string){
    if(apiOptions.showLoader){
      this.loaderService.hideLoader();
    }
  }
}
