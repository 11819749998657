import { Injectable, signal } from "@angular/core";
import { BehaviorSubject, Observable, Subject, exhaustMap, lastValueFrom, of, switchMap, switchMapTo } from "rxjs";
import { RequestPath } from "src/app/core/constants/request-path.const";
import { ApiResponse } from "src/app/core/interfaces/api-reponse.model";
import { BaseService } from "src/app/core/services/base-service.class";
import { environment } from "src/environments/environment";
import { Parameter } from "../models/parameter.model";
import { Symptom } from "../models/symptom.model";
import { RequestReportData } from "../models/modified-input-data.model";
import { AiReportRequest, ReportResponse } from "../models/report-response.model";
import { AiReport } from "../models/ai-report.model";



@Injectable({providedIn:"root"})
export class ReportService extends BaseService{

  parameterSearchTerm = new BehaviorSubject('blood');
  searchKey$ = this.parameterSearchTerm.asObservable();

  private baseUrl = signal(environment.API_URL);

  getParameters(keyword:string): Observable<ApiResponse<{tests:[Parameter[],number], symptoms:[Symptom[], number]}>>{
    const url = this.baseUrl() + RequestPath.getParameter + keyword;
    return this.httpService.get(url);
  }

  async getRecordById(id:number): Promise<ApiResponse<ReportResponse>>{
    return lastValueFrom(this.httpService.get(this.baseUrl()+RequestPath.getRecord+`${id}`+'/patient'));
  }

  async getSharedRecordById(id:number): Promise<ReportResponse>{
    return lastValueFrom(this.httpService.get(this.baseUrl()+RequestPath.sharedRecord+`${id}`));
  }

  async makeRecordPublic(payload:{id:number, isPublic:boolean}): Promise<boolean>{
    return lastValueFrom(this.httpService.post(this.baseUrl()+RequestPath.makeRecordPublic, payload));
  }

  async findSymptoms(payload:any): Promise<ApiResponse<[Parameter[], number]>>{
    const path = RequestPath.findSymptoms;
    return lastValueFrom(this.httpService.post(this.baseUrl()+path, payload, {headers:{"Access-Name":this.getAccessName(path)}}));
  }

  postReportImage(body:FormData){
    const options = {reportProgress: true, observe: 'events'};
    const appOptions = {showLoader:false};
    return this.httpService.post(this.baseUrl()+RequestPath.fileUpload,body, options, appOptions);
  }

  extractTextFromDocs(body:FormData): Observable<ApiResponse<string>>{
    const options = {reportProgress: true, observe: 'events'};
    const appOptions = {showLoader:false};
    return this.httpService.post(this.baseUrl()+RequestPath.extractText,body, options, appOptions);
  }


  getDummy(){
    return this.httpService.get("/assets/json/dummy-ocr.json");
  }

  async generateReport(payload: RequestReportData): Promise<ApiResponse<ReportResponse>>{
    const appOptions = {showLoader:false};
    const numberStringifiedPayload = this.convertNumbersToStrings(payload);
    return lastValueFrom(this.httpService.post(this.baseUrl()+RequestPath.generateReport, numberStringifiedPayload,undefined,appOptions));
  }

  async updateReport(payload: RequestReportData, id: number): Promise<ApiResponse<ReportResponse>>{
    const appOptions = {showLoader:false};
    const numberStringifiedPayload = this.convertNumbersToStrings(payload);
    return lastValueFrom(this.httpService.put(this.baseUrl()+RequestPath.updateReport+id, numberStringifiedPayload,undefined,appOptions));
  }

  async updateRecord(payload: Partial<RequestReportData>, id: number): Promise<ApiResponse<ReportResponse>>{
    const appOptions = {showLoader:false};
    return lastValueFrom(this.httpService.put(this.baseUrl()+RequestPath.updateRecord+id, payload,undefined,appOptions));
  }

  findDiseaseSymptomById(payload:{ids:{id:number}[]}): Promise<ApiResponse<Symptom[]>>{
    const appOptions = {showLoader:false};
    const path = RequestPath.getDiseaseSymptom;
    return lastValueFrom(this.httpService.post(this.baseUrl()+path, payload, {headers:{"Access-Name":this.getAccessName(path)}}, appOptions));
  }

  getAiGeneratedReport(payload:AiReportRequest, id:number): Observable<ApiResponse<AiReport>>{
    const appOptions = {showLoader:false};
    return this.httpService.put(this.baseUrl()+RequestPath.generateAiReport+id, payload, undefined, appOptions);
  }

  findParameter<T>(payload:any, path:string): Promise<ApiResponse<T>>{
    return lastValueFrom(this.httpService.post(this.baseUrl()+path, payload));
  }


  async findParmById(path:string, payload:any): Promise<ApiResponse<[Parameter[],number]>>{
    return lastValueFrom(this.httpService.post(this.baseUrl()+path, payload, {headers:{"Access-Name":this.getAccessName(path)}}));
  }


  private convertNumbersToStrings(data: JsonValue): JsonValue {
    if (typeof data === 'number') {
      // Convert number to string
      return data.toString();
    } else if (Array.isArray(data)) {
      // Recursively process each item in the array
      return data.map(item => this.convertNumbersToStrings(item));
    } else if (typeof data === 'object' && data !== null) {
      // Recursively process each key-value pair in the object
      const newData: JsonObject = {};
      for (const [key, value] of Object.entries(data)) {
        newData[key] = this.convertNumbersToStrings(value);
      }
      return newData;
    } else {
      // Return the data unchanged if it's not a number, array, or object
      return data;
    }
  }

}


type JsonValue = string | number | boolean | null | JsonArray | JsonObject | Date;
interface JsonArray extends Array<JsonValue> {}
interface JsonObject {
  [key: string]: JsonValue;
}
