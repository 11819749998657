<div class="flex flex-col gap-1 relative">
  <label [for]="placeholder"  class=" text-xs font-medium" *ngIf="label">{{label}}</label>
  <mat-form-field appearance="outline" [ngClass]="['form-field-wrapper', bgClass, hasError ? 'error-outline':'']">
    <input [type]="type" [ngClass]="bgClass" matInput #searchKey
    [placeholder]='placeholder' [name]="placeholder"
    [formControl]="acFormControl"
    [matAutocomplete]="auto" [readonly]="readOnly">
  <mat-autocomplete #auto="matAutocomplete" [ariaReadOnly]="disabled" [displayWith]="displayFn" (optionSelected)="onSelectionChange()">
    <ng-container *ngIf="!readOnly">
      <mat-option class="py-1" *ngFor="let option of filteredOptions | async" [value]="option['value']" (optionSelected)="onSelectionChange()">
       <div>
        {{option['label'] | titlecase}}
       </div>
       <div class="text-xs font-medium text-zinc-400" *ngIf="option.aliases">
        <div [innerHTML]="option.aliases.join(', ') | searchHighlight :searchText.nativeElement.value"></div>
       </div>
       </mat-option>
    </ng-container>
  </mat-autocomplete>
  </mat-form-field>
  <p class="text-red-600 text-xs absolute error-text">
    <ng-content #content></ng-content>
  </p>
</div>
