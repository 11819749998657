import { Component } from '@angular/core';
import { BaseComponent } from 'src/app/core/components/base-component.class';

@Component({
  selector: 'app-verify-response',
  templateUrl: './verify-response.component.html',
  styleUrls: ['./verify-response.component.scss']
})
export class VerifyResponseComponent extends BaseComponent{

  content:string = '';
  constructor(){
    super();
    this.content = history.state['content'];
  }

}
