import { Injectable, inject } from "@angular/core";
import { Utils } from "src/app/core/utils/utils.class";
import { AgeGroupNumber } from "../constants/age-group-number.const";
import { Operation } from "../constants/operation.enum";
import { ResultType } from "../constants/result-types.enum";
import { CdssTestError } from "../models/cdss-test-error.model";
import { ModifiedInputData } from "../models/modified-input-data.model";
import { CdssTestResultTypeMinMax, Parameter, ResultTypeValues } from "../models/parameter.model";
import { PatientInfo } from "../models/patient-info.model";
import { ValueMapperService } from "./value-mapper.service";
import { AgeGroup } from "../constants/age-group.enum";
import { ReportFormatService } from "./report-format.service";

@Injectable({
  providedIn: 'root'
})
export class ReportMappingService {
  valueCodeMapperService = inject(ValueMapperService);

  normalizeValues(originalParam: ModifiedInputData[], basicInfo: PatientInfo): { data: ModifiedInputData[], error: CdssTestError[] } {
    let errorArray: CdssTestError[] = [];
    originalParam.forEach((cdssTest, index: number) => {
      const isValuePresent = !!cdssTest.value || cdssTest.value?.length > 0 || cdssTest.value == 0;
      if (isValuePresent) {
        const resultType = cdssTest.parameter;
        cdssTest.value = this.valueCodeMapperService.getValueByMapperCode(cdssTest.value, resultType!.resultType)
        if (!!cdssTest.parameter?.resultType) {
          if (cdssTest.parameter?.resultType !== ResultType.MIN_MAX) {
            if (cdssTest.parameter?.resultType === ResultType.OTM) {
              if (cdssTest.value.length < 1) {
                const invalidValue: CdssTestError = {
                  index: index,
                  name: cdssTest.parameter?.name!,
                  message: 'Please fill values manually',
                  type: 'value',
                  option: '',
                  testId: cdssTest.testId!
                }
                errorArray.push(invalidValue);
              }
            } else if(cdssTest.parameter.canBeMinmax && cdssTest.isCanBeValidMinmax){
              const value = this.valueCodeMapperService.getMappedValueFromCanBeMinmax(cdssTest.value!, cdssTest.range!, cdssTest.unit!);
              cdssTest.value = this.valueCodeMapperService.getValueByMapperCode(value,cdssTest.parameter.resultType);
              delete cdssTest.range;
              delete cdssTest.unit;
            } else if(cdssTest.parameter.resultType === ResultType.NOT_DEFINED){
              const invalidValue: CdssTestError = {
                index: index,
                name: cdssTest.parameter?.name!,
                message: 'Invalid Test Name. Please select manually',
                type: 'value',
                option: '',
                testId: cdssTest.testId!
              };
              errorArray.push(invalidValue);
              return;
            }
             else { //Multiple option select (PRESENT_ABSENT, INCREASED_DECREASED, ...);
              const valueType = cdssTest.parameter?.resultType?.toUpperCase().split("_");
              const isValidValue = isNaN(cdssTest.value) ? valueType?.includes(cdssTest.value.toUpperCase()) : false;

              const invalidValue: CdssTestError = {
                index: index,
                name: cdssTest.parameter?.name!,
                message: 'Invalid value. Please select manually',
                type: 'value',
                option: '',
                testId: cdssTest.testId!
              };

              if (!isValidValue) {
                errorArray.push(invalidValue);
              } else{
                cdssTest.value = cdssTest.value.toUpperCase();
              }

            }
            return;
          }
          cdssTest.value = isNaN(cdssTest.value) ? Utils.removeCommasAndConvert(cdssTest.value) : cdssTest.value; //to remove comma in between numbers
          if (isNaN(cdssTest.value)) { //min max value should be a number
            const invalidValue: CdssTestError = {
              index: index,
              name: cdssTest.parameter?.name!,
              message: 'Value should be a number',
              type: 'value',
              option: '',
              testId: cdssTest.testId!
            }
            errorArray.push(invalidValue);
            return;
          }
          const unitValues: string[] = this.getUnitValues(cdssTest.parameter?.resultTypeValues!); //to get unit name with alias together
          const checkedTestResult = this.checkValidResultType(basicInfo, cdssTest, index, unitValues); //to check input validation and get normalized value and unit
          cdssTest.value = Number(checkedTestResult.updatedResult.value);
          cdssTest.unit = checkedTestResult.updatedResult.unit;
          cdssTest.hasValueNormalized = checkedTestResult.errMarginNormalized;
          errorArray = errorArray.concat(checkedTestResult.error);
        } else { //Parameter is Symptom
          const validSymptom = ResultType.PRESENT_ABSENT.toUpperCase().split("_").includes(cdssTest.value.toUpperCase());
          if (!validSymptom) {
            const invalidValue: CdssTestError = {
              index: index,
              name: cdssTest.parameter?.name!,
              message: 'Invalid value. Please select manually',
              type: 'value',
              option: '',
              testId: -1
            }
            errorArray.push(invalidValue);
          }
        }
      } else {
        const invalidValue: CdssTestError = {
          index: index,
          name: cdssTest.parameter?.name!,
          message: 'Value should not be empty',
          type: 'value',
          option: '',
          testId: cdssTest.testId!
        }
        errorArray.push(invalidValue);
      }
    })
    const errArray = errorArray.length > 0 ? this.removeDuplicatesFromArray(errorArray) : [];  //to remove duplicate error from array, if array has data
    return { data: originalParam, error: errArray };
  }

  checkValidInput(basicInfo: PatientInfo, ruleValue: { ageGroup: string[], country: string[], gender: string }, hasElder: boolean) {
    const age = this.findCategoryByAge(basicInfo.age);
    const ignoreElder = hasElder ? "ELDERLY" : "ADULT"; //Includes elder if rule is adult
    const country = basicInfo.country;
    const gender = basicInfo.gender;
    const validAge = (ruleValue.ageGroup.includes(age) || ruleValue.ageGroup.includes("ALL") || (!hasElder && ruleValue.ageGroup.includes(ignoreElder))); //ruleValue.ageGroup == "ignoreElder"
    const validCountry = (ruleValue.country.includes(country) || ruleValue.country.includes("All Countries"));
    const validGender = (ruleValue.gender == gender || ruleValue.gender == "BOTH");
    const validInput = validAge && validCountry && validGender;

    return validInput;
  }


  findCategoryByAge(age: number): string {
    for (const category in AgeGroupNumber) {
      if (AgeGroupNumber.hasOwnProperty(category)) {
        const ageRange = (AgeGroupNumber as any)[category].split('_');
        const minAge = +ageRange[0];
        const maxAge = +ageRange[1];
        if (age >= minAge && age <= maxAge) {
          return category;
        }
      }
    }
    return "ALL";
  }

  calculate(operation: Operation, userValue: number, tallyValue: number): number {
    switch (operation) {
      case Operation.ADDITION:
        return userValue + tallyValue;
      case Operation.SUBTRACTION:
        return userValue - tallyValue;
      case Operation.MULTIPLICATION:
        return userValue * tallyValue;
      case Operation.DIVISION:
        return userValue / tallyValue;
      case Operation.PERCENTAGE:
        return userValue * 100 / tallyValue;
      default:
        return userValue;
    }
  }

  checkValidRange(reportRange: string, cdssTest: Parameter): { validRange: boolean, reportMin: number, reportMax: number, hasInvalidRange:boolean } {
    const tempRange = reportRange.split('-');
    const [reportMin, reportMax] = [+tempRange[0], +tempRange[1]];
    const hasInvalidMinMax = isNaN(reportMin) || isNaN(reportMax) || reportMax<=0;
    if(hasInvalidMinMax){
      return { validRange: false, reportMin: reportMin, reportMax: reportMax, hasInvalidRange: true };
    }
    const validRange = cdssTest.resultTypeValues?.some(resultType => resultType.cdssTestResultTypeMinMax!.min == reportMin && resultType.cdssTestResultTypeMinMax!.max == reportMax);
    return { validRange: !!validRange, reportMin: reportMin, reportMax: reportMax, hasInvalidRange:false };
  }

  appendErrorMargin(ruleValue: CdssTestResultTypeMinMax, parameter: Parameter) {
    const errMargin = parameter.errorMargin!;
    if (parameter.errorMarginType == "PERCENTAGE") {
      const min = +ruleValue.min - this.getPercentage(errMargin, +ruleValue.min)
      const max = +ruleValue.max + this.getPercentage(errMargin, +ruleValue.max)
      return `${min + "-" + max}`;
    } else {
      return `${(ruleValue.min - errMargin) + "-" + (ruleValue.max + errMargin)}`;
    }
  }

  getPercentage(value: number, percentage: number) {
    return value * (percentage / 100);
  }

  convertValueIntoRange(value: number, fromMin: number, fromMax: number, toMin: number, toMax: number) {
    const notAnNumber = isNaN(fromMin) && isNaN(fromMax) && isNaN(toMin) && isNaN(toMax);
    if (notAnNumber) {
      return value;
    }
    const valueInReportRange = (value - fromMin) / (fromMax - fromMin);
    const ruleValue = toMin + valueInReportRange * (toMax - toMin);
    return  ruleValue;
  }

  checkValidResultType(basicInfo: PatientInfo, cdssTest: ModifiedInputData, index: number, unitValues: string[]) {
    const resultTypeValues: ResultTypeValues[] = cdssTest.parameter?.resultTypeValues!;
    let validTest = false;
    let hasValueNormalized = false;
    let errorArray: CdssTestError[] = [];
    if (resultTypeValues.length < 1) {
      const msg = "No unit found for this test"
      const error = this.generateErrorMsg(index, "unit", cdssTest.unit!, cdssTest.parameter?.name!, "", cdssTest.testId!, msg);
      errorArray.push(error);
    } else {
      if (!!cdssTest?.value || cdssTest.value == 0) {
        if (!!cdssTest.unit?.trim()) {
          const hasElder = resultTypeValues?.some(resultType => resultType.ageGroup.includes(AgeGroup.ELDERLY)) && this.findCategoryByAge(basicInfo.age) === 'ELDERLY';
          resultTypeValues.forEach(result => {
            const validInput = this.checkValidInput(basicInfo, result, !!hasElder);
            if (validInput) {
              ///Step2: Convert report unit into rule's unit
              const testUnit = result.cdssTestResultTypeMinMax!.unitName;
              const isUnitMatchWithUserInput = (cdssTest.unit?.trim().toLowerCase() === testUnit.name.trim().toLowerCase()) || (cdssTest.unit?.trim().toLowerCase() === testUnit.code.trim().toLowerCase()); ///case insensitive
              let validAliasUnit = false;
              if (!isUnitMatchWithUserInput) {
                const aliasUnit = testUnit.aliases.find(alias => alias.name.trim().toLowerCase() == cdssTest.unit?.trim().toLowerCase()); ///case insensitive
                if (!!aliasUnit) {
                  validAliasUnit = true;
                  /**
                   * Removed unit alias calculation since we are considering users normal range to change user's actual value
                   * When unit alias calculation implemented we are not consider user's normal range.
                   */

                  //const tempUnit = aliasUnit;
                  //cdssTest.unit = testUnit.name.trim();
                  //cdssTest.value = this.calculate(tempUnit.operation, cdssTest.value, tempUnit.value);
                }
              }
              const validUnit = isUnitMatchWithUserInput || validAliasUnit;
              validTest = !validTest ? validInput && validUnit : true;
              ///step3: Convert report range value into rule range value
              if (validUnit) {
                const reportRange = structuredClone(cdssTest.range!);
                const tempRange = this.checkValidRange(cdssTest.range!, cdssTest.parameter!);
                const hasValidRange = !tempRange.hasInvalidRange;
                const ruleValue = result.cdssTestResultTypeMinMax!;
                cdssTest.range = `${ruleValue.min + "-" + ruleValue.max}` ?? cdssTest.range; ///changes required
                if(hasValidRange){
                  if (!tempRange.validRange) {
                    const minRange = isNaN(Number(tempRange.reportMin)) ? ruleValue.min : Number(tempRange.reportMin);
                    const maxRange = isNaN(Number(tempRange.reportMax)) ? ruleValue.max : Number(tempRange.reportMax);
                    //convert value into system rule range
                    cdssTest.value = this.convertValueIntoRange(Number(cdssTest.value), minRange, maxRange, Number(ruleValue.min), Number(ruleValue.max));
                  }
                  ///step4: Check value present within error margin's range
                  const errRange = this.appendErrorMargin(ruleValue, cdssTest.parameter!);
                  const beforeErrMarginNormalization = this.checkValuePresentInNormalRange(cdssTest.value, ruleValue.min, ruleValue.max);
                  const ruleErrRange = errRange.split("-");
                  //convert value into error margin range
                  cdssTest.value = this.convertValueIntoRange(+cdssTest.value, +ruleErrRange[0], +ruleErrRange[1], +ruleValue.min, +ruleValue.max).toFixed(4);
                  const afterErrMarginNormalization = this.checkValuePresentInNormalRange(cdssTest.value, ruleValue.min, ruleValue.max);
                  hasValueNormalized = !beforeErrMarginNormalization && afterErrMarginNormalization;

                } else{
                  validTest = false;
                  const msg = "Please select your range manually"
                  const error = this.generateErrorMsg(index, "range", "", cdssTest.parameter?.name!, reportRange, cdssTest.testId!, msg);
                  errorArray.push(error);
                }
              } else {
                const option = unitValues.join(', ')
                const error = this.generateErrorMsg(index, "unit", cdssTest.unit!, cdssTest.parameter?.name!, option, cdssTest.testId!);
                errorArray.push(error);
              }
            } else {
              const option = `${basicInfo.age}/${basicInfo.gender}/${basicInfo.country}`;
              const error = this.generateErrorMsg(index, "input", cdssTest.unit!, cdssTest.parameter?.name!, option, cdssTest.testId!);
              errorArray.push(error);
            }
          })
          if (!validTest) {
            errorArray = this.ignoreInvalidErrors(errorArray, unitValues);
          } else {
            errorArray = []
          }
        } else {
          const msg = "Unit should not be empty"
          const error = this.generateErrorMsg(index, "value", "", cdssTest.parameter?.name!, "", cdssTest.testId!, msg);
          errorArray.push(error);
        }
      } else {
        const msg = "Value should not be empty"
        const error = this.generateErrorMsg(index, "value", "", cdssTest.parameter?.name!, "", cdssTest.testId!, msg);
        errorArray.push(error);
      }
    }
    return { updatedResult: cdssTest, error: errorArray, errMarginNormalized: hasValueNormalized }
  }

  getUnitValues(resultTypeValues: ResultTypeValues[]) {
    let unitValues: string[] = [];
    resultTypeValues.forEach((resultType: ResultTypeValues) => {
      const unit = resultType.cdssTestResultTypeMinMax!.unitName;
      ///unit options
      const unitAlias = unit.aliases.map(alias => alias.name);
      unitValues.push(unit.name)
      unitValues = [...unitValues, ...unitAlias];
    })
    return unitValues;
  }

  removeDuplicatesFromArray(arr: CdssTestError[]): CdssTestError[] {
    const uniqueIndices: { [key: number]: boolean } = {};
    return arr.filter((obj) => {
      if (!uniqueIndices[obj.index]) {
        uniqueIndices[obj.index] = true;
        return true;
      }
      return false;
    });
  }

  checkValuePresentInNormalRange(value: number, min: number, max: number) {
    return +value >= +min && +value <= +max ? true : false;
  }

  generateErrorMsg(index: number, type: "input" | "unit" | "value" | "range", unit: string, name: string, option: string, id: number, msg = `We are not supporting this unit`
  ): CdssTestError {
    const err: CdssTestError = {
      index: index,
      message: msg,
      type: type,
      unit: unit,
      name: name,
      option: option,
      testId: id
    }
    return err;
  }

  ignoreInvalidErrors(errorArray:CdssTestError[], unitValues:string[]){
    let unitError = errorArray.filter(error => error.type == "unit");
    let inputError = errorArray.filter(error => error.type == "input");
    let rangeError = errorArray.filter(error=>error.type == "range");
    if(rangeError.length>0){
      const rangeIds = rangeError.map(err=>err.testId);
      inputError = inputError.filter(err=>!rangeIds.includes(err.testId));
    }
    unitError = unitError.filter(error => !unitValues.includes(error.unit!));
    errorArray = [...inputError, ...unitError, ...rangeError];
    return errorArray;
  }

}
