export class Utils {
  static removeCommasAndConvert(input: string): number {
    const withoutCommas = input.replace(/,/g, ''); // Remove commas globally
    const numericValue = Number(withoutCommas); // Convert to integer
    return numericValue;
  }

  static getAgeByDob(dateOfBirth: Date): number {
    const currentDate = new Date();
    const dob = new Date(dateOfBirth);
    let age = currentDate.getFullYear() - dob.getFullYear();
    if (
      currentDate.getMonth() < dob.getMonth() ||
      (currentDate.getMonth() === dob.getMonth() &&
        currentDate.getDate() < dob.getDate())
    ) {
      age--;
    }
    return age > 0 ? age : 1;
  }

  static removeDuplicatesFromArray<T>(array: T[]): T[] {
    return array.filter((item, index, self) =>
      index === self.findIndex((i) =>
        JSON.stringify(i) === JSON.stringify(item)
      )
    );
  }

  static capitalizeFirstLetter(input: string): string {
    if (input.length === 0) {
      return input; // Return the input unchanged if it's an empty string
    }

    return input.charAt(0).toUpperCase() + input.slice(1);
  }

  static isDuplicateEntry(arr: object[], obj:object): boolean {
    for (let i = 0; i < arr.length; i++) {
        if (Utils.isEqual(arr[i], obj)) {
          return true; // Found a duplicate object
        }
    }
    return false; // No duplicate objects found
  }

  static isEqual(obj1: object, obj2: object): boolean {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }

  static formatAliases(array:any[]){
    if(!array.length) return [];
     const aliases = array.filter(a=>!a.name.includes('~')).map(a=>a.name);
     return aliases;
  }
}
