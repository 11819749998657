import { Component, OnInit, inject } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { BaseFormComponent } from 'src/app/core/components/base-form.class';
import { RoutePath } from 'src/app/core/constants/route.const';
import { MessageType } from 'src/app/shared/models/message-type.enum';
import { ValidationPatterns } from 'src/app/core/utils/validation-patterns';
import { ResetPassword } from '../../models/reset-password.model';
import { AuthService } from '../../services/auth.service';
import { comparePassword } from 'src/app/core/utils/validations';


@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent extends BaseFormComponent implements OnInit{
  resetForm!:FormGroup;


  get formControl() {
    return this.resetForm.controls;
  }

  private authService = inject(AuthService);

  ngOnInit(): void {
    this.resetForm=this.fb.group({
      password:['', [Validators.required, Validators.pattern(ValidationPatterns.password)]],
      confirmPassword:['', [Validators.required, comparePassword]]
    },
    {validators: comparePassword}
    )
  }

  async onSubmit(){
    this.isSubmitted.set(true);
    if(this.resetForm.valid){
      const payload:ResetPassword = {
        email: history.state['email'],
        otp: history.state['otp'],
        password: this.resetForm.value['password']
      }
      const response = await this.authService.resetPassword(payload);
      if(response.status){
        this.router.navigateByUrl(RoutePath.verifyResponse, {state:{title:'Success!', content:'Your password has been reset successfully'}});
      }else{
        this.notificationService.notify(response.message, MessageType.ERROR)
      }

    }
  }

}


