import { DurationPeriod } from "src/app/shared/constants/duration-period-type.enum";

export class ReportUtils{

  static checkCanBeMinmaxType(value:any, canBeMinmax:boolean) :boolean{
    if(!canBeMinmax) return false;
    const isMinmax = typeof(value) === 'object' && !!value.value && !!value.range && !!value.unit;
    return isMinmax;
  }

  static getValueFromCanBeMinmax(value:any, canBeMinmax:boolean){
    if(!canBeMinmax) return value;
    return value.value;
  }

  static getRangeFromCanBeMinmax(value:any, canBeMinmax:boolean) :string{
    if(!canBeMinmax) return '';
    return value.range;
  }

  static convertAgeIntoYear(actualAge:{age: number, period: DurationPeriod}): number{
    const age = actualAge.age ?? 1;
    switch(actualAge.period) {
        case DurationPeriod.HOUR:
          return Math.floor(age / (24 * 365));
          case DurationPeriod.DAY:
            return Math.floor(age / 365); // Assuming average year has 365 days
        case DurationPeriod.WEEK:
            return Math.floor(age / 52.143); // Approximate weeks in a year
        case DurationPeriod.MONTH:
            return Math.floor(age / 12); // 12 months in a year
        case DurationPeriod.YEAR:
            return age; // Already in years
        default:
            return age;
    }
  }

  static getUnitFromCanBeMinmax(value:any, canBeMinmax:boolean) :string{
    if(!canBeMinmax) return '';
    return value.unit;
  }

}
