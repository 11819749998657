<article class="py-4 px-4">
  <h2  class="text-xl font-medium text-gray-700">How to use the system</h2>

<mat-dialog-content class="ml-8 text-black">
  <ol class="list-decimal">
      <li class="mb-2">Signup and get yourself a user ID and password</li>
      <li class="mb-2">Login to the system</li>
      <li class="mb-2">If you wish to upload a lab report click on the Upload Report option.</li>
      <li class="mb-2">Upload the report and wait. You will get a message saying report uploaded and then a message saying "processing." WAIT until this is completed. This may take a minute or two depending upon the number of tests in your report.</li>
      <li class="mb-2">Once processing is completed, the system will fetch the data in your uploaded document and will display the same. Check the list to make sure nothing has been left out.</li>
      <li class="mb-2">Sometimes if some data has not been read currently or there is a unit mismatch, the system will give an alert with a list of tests where such discrepancies exist.</li>
      <li class="mb-2">Click the "No" button should such a message be displayed and make the corrections.</li>
      <li class="mb-2">Once you have verified that all the tests are properly displayed, check the box at the bottom that confirms the same.</li>
      <li class="mb-2">Click on the "Analyze My report" button and wait for 30 seconds.</li>
      <li class="mb-2">You will see a prompt with a set of symptoms based on the test results for you to choose if they are present or absent. For an even more accurate result select those that are present with the duration.</li>
      <li class="mb-2">If you don't wish to enter symptoms click Ignore and the Analysis will be done. If you have entered the symptoms, click on the Proceed button to generate the Analysis.</li>
      <li class="mb-2">The analysis will be displayed in detail.</li>
      <li class="mb-2">If you wish to manually enter the report or symptom data, select the option, Enter Manually at the start and proceed as before.</li>
  </ol>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-stroked-button color="primary" mat-dialog-close *ngIf="!hasDisplayedUserGuide" (click)="updateDisplayUserGuide()">Don't show me again</button>
  <button mat-flat-button color="primary" mat-dialog-close cdkFocusInitial>Got it!</button>
</mat-dialog-actions>


</article>
