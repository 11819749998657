import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { SelectOption } from 'src/app/shared/models/select-option.model';

/**
 * To validate password and confirm password
 *
 * @export
 * @param {AbstractControl} controls
 * @return {*}  {*}
 */
export function comparePassword(
  controls: AbstractControl
): ValidationErrors | null {
  const password = controls.get('password')?.value ?? '';
  const confirmPassword = controls.get('confirmPassword')?.value ?? '';
  const checkBothSame = password && password === confirmPassword;

  if (!checkBothSame) {
    controls.get('confirmPassword')?.setErrors({
      passwordMismatch: true,
    });
  }

  return null;
}

/**
 *
 *
 * @export
 * @param {FormControl} control
 * @return {*}
 */
export function validateStrongPassword(control: FormControl): any {
  const password: string = control.value;
  const regex = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,})/;
  const checkPasswordStrength = password.match(regex);
  if (!checkPasswordStrength) {
    return {
      passwordStrength: true,
    };
  }

  return null;
}

export function countryValidator(countries: SelectOption[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const inputValue: string = control.value;

    if (!inputValue) {
      return null; // No validation error if the field is empty
    }

    const isValidCountry = countries.find(country=>country.label?.toLowerCase() === inputValue?.toLowerCase());

    return isValidCountry ? null : { invalidCountry: true };
  };
}
