<app-grid-layout-template backgroundImageUrl="/assets/images/drugs-bg.png">
  <div class="text-center text-white">
    <!-- <div>SIGN IN</div> -->
    <div  class="text-4xl font-light">Reset Password</div>
  </div>
  <form class="flex flex-col gap-4 mt-4 w-full mx-auto" [formGroup]="resetForm" (ngSubmit)="onSubmit()">
    <div class="flex justify-center items-center mb-2">
      <app-text-input class="w-full text-white" name="password" label="Password" placeholder="Password" [type]="textInputType.PASSWORD" formControlName="password" [hasError]="checkErrorMessage(resetForm, 'password', isSubmitted())">
        <ng-container *ngIf="checkErrorMessage(resetForm, 'password', isSubmitted())">
          <p *ngIf="formControl['password'].errors!['required']">
            Password required.
          </p>
          <p *ngIf="formControl['password'].errors!['pattern']">
            Password should contain at least six character and includes a number, special character, small and capital letters.
          </p>
        </ng-container>
      </app-text-input>
    </div>
    <div class="flex justify-center items-center my-2">
      <app-text-input class="w-full text-white" name="confirmPassword" label="Confirm Password" placeholder="Confirm Password" [type]="textInputType.PASSWORD" formControlName="confirmPassword" [hasError]="checkErrorMessage(resetForm, 'password', isSubmitted())">
        <ng-container *ngIf="checkErrorMessage(resetForm, 'confirmPassword', isSubmitted())">
          <p *ngIf="formControl['confirmPassword'].errors!['required']">
            Confirm Password required.
          </p>
          <p *ngIf="formControl['confirmPassword'].errors!['passwordMismatch']">
            Confirm Password should match Password
          </p>
        </ng-container>
      </app-text-input>
    </div>
    <footer>
      <button class="rounded w-full flex justify-center items-center my-2 py-2 font-medium bg-primary_dark" type="submit">
        <div class="flex-1 text-center text-white">CONTINUE</div>
      </button>
      <div class="flex justify-center items-center">
        <div class="flex-1 text-center text-white font-light text-xs my-2">
          <a [routerLink]="'/auth/sign-in'">Back to Login</a>
        </div>
      </div>
    </footer>

</form>
</app-grid-layout-template>
