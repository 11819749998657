<form [formGroup]="multiFieldForm" class="relative w-full">
  <div class="border border-secondary text-black text-sm border-1 rounded-xl flex flex-nowrap overflow-hidden" [ngClass]=" hasError ? 'error-outline':''">
    <input formControlName="value" class="p-3 w-1/3 border-secondary border-r border-r-1 flex justify-end gap-2 items-center" [ngClass]=" hasError ? 'error-border':''"
      placeholder="Value" [readOnly]="readOnly" (ngModelChange)="getMultiFieldValue()">

       <input formControlName="range" class="p-3 w-1/3 border-secondary border-r border-r-1" [ngClass]=" hasError ? 'error-border':''"  placeholder="Range"  [readOnly]="readOnly"
    (ngModelChange)="getMultiFieldValue()" [matAutocomplete]="rangeValue">
    <mat-autocomplete #rangeValue="matAutocomplete">
      <mat-option *ngFor="let option of rangeOptions; let i = index" [value]="option">
        {{option}}
      </mat-option>
    </mat-autocomplete>

    <input formControlName="unit" class="p-3 w-1/3 flex justify-end gap-2 items-center"   placeholder="Unit"  [readOnly]="readOnly"
    (ngModelChange)="getMultiFieldValue()" [matAutocomplete]="unitValue">
    <mat-autocomplete #unitValue="matAutocomplete">
      <mat-option *ngFor="let option of unitOptions; let i = index" [value]="option">
        {{option}}
      </mat-option>
    </mat-autocomplete>
  </div>
  <p class="text-red-600 text-xs absolute error-text">
    <ng-content #content></ng-content>
  </p>
</form>

