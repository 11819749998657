import { Component, EventEmitter, Input, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DurationPeriod } from 'src/app/shared/constants/duration-period-type.enum';
import { TextInputType } from 'src/app/shared/constants/text-input-type.enum';

@Component({
  selector: 'app-duration-input',
  templateUrl: './duration-input.component.html',
  styleUrls: ['./duration-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DurationInputComponent),
      multi: true,
    },
  ],
})
export class DurationInputComponent implements ControlValueAccessor{

  @Input() label = '';
  @Input() placeholder:string='Ex. 2';
  @Input() bgClass:string='bg-white'
  @Input() hasError:boolean = false;
  @Input() readOnly:boolean = false;
  @Output() onValueChange:EventEmitter<boolean> = new EventEmitter();


  textType = TextInputType;
  periodOptions = Object.keys(DurationPeriod).map((type) => ({
    label: type.replace(/_/g, ' '),
    value: type,
  }));

  hide:boolean=true;
  inputForm = new FormGroup({
    value: new FormControl <number|undefined>(undefined),
    period: new FormControl <string>(DurationPeriod.DAY),
  })

  propagateChange = (_: any) => {};
  touchChange = (_: any) => {};

  onInputValueChange(){
    this.propagateChange(this.inputForm.value);
    this.onValueChange.emit(true);
  }

  writeValue(value: any): void {
    if(!!value){
      this.inputForm.setValue(value);
      return;
    }
    this.inputForm.controls.period.setValue(DurationPeriod.DAY)
  }

  registerOnChange(fn: any): void {
    this.propagateChange=fn;
  }

  registerOnTouched(fn: any): void {
    this.touchChange=fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    // throw new Error('Method not implemented.');
  }

}
