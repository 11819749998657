<div class="mb-6 flex items-center">
  <div class="flex gap-4 w-full">
    <div class="flex flex-1 gap-2 font-semibold text-2xl items-center">
      <button (click)="onBack()"> <img src="/assets/images/back.svg"></button>
      <h2>{{title | titlecase}}</h2>
    </div>
    <div class="flex">
      <ng-content #content></ng-content>
    </div>
  </div>
</div>
