<div class="flex flex-col gap-1 relative">
  <label [for]="placeholder"  class=" text-xs font-medium" *ngIf="label">{{label}}</label>
  <mat-form-field appearance="outline"  [ngClass]="['form-field-wrapper', bgClass, hasError ? 'error-outline':'']">
    <mat-select [formControl]="selectFormControl" (selectionChange)="onSelectionChange()" [placeholder]="placeholder" [multiple]="multiple">
      <mat-option *ngFor="let option of options" [value]="option['value']" [disabled]="readOnly">{{option.label}}</mat-option>
    </mat-select>
  </mat-form-field>
  <p class="text-red-600 text-xs absolute error-text">
    <ng-content #content></ng-content>
  </p>
</div>
