import { Location } from '@angular/common';
import { Component, Input, inject } from '@angular/core';

@Component({
  selector: 'app-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss']
})
export class PageTitleComponent {
  location = inject(Location);


  @Input() title!:string;

  onBack(){
    this.location.back();
  }

}
