import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-grid-layout-template',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './grid-layout-template.component.html',
  styleUrls: ['./grid-layout-template.component.scss']
})
export class GridLayoutTemplateComponent {

  @Input() backgroundImageUrl!:string;
  @Input() hideLogo:boolean = false;

}
