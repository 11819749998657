import { Injectable, inject } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MessageType } from 'src/app/shared/models/message-type.enum';

@Injectable({ providedIn: 'root' })
export class NotificationService {

  notify(msg: string,
    type: MessageType.ERROR | MessageType.INFO | MessageType.SUCCESS | MessageType.WARNING,
    title?: string,
    snackBarConfig: MatSnackBarConfig = {
      duration: 3000
    }
  ) {
    snackBarConfig = {
      duration: 3000,
      verticalPosition: 'top',
      horizontalPosition: 'right',
      ...snackBarConfig
    }
    switch (type) {
      case MessageType.ERROR: {
        snackBarConfig.panelClass = 'error-snack'
        this.snackBar.open(msg, 'close', snackBarConfig)
        break;
      }
      case MessageType.SUCCESS: {
        snackBarConfig.panelClass = 'success-snack'
        this.snackBar.open(msg, 'close', snackBarConfig)
        break;
      }
      case MessageType.INFO: {
        snackBarConfig.panelClass = 'info-snack'
        this.snackBar.open(msg, 'close', snackBarConfig)
        break;
      }
      case MessageType.WARNING: {
        snackBarConfig.panelClass = 'warning-snack'
        this.snackBar.open(msg, 'close', snackBarConfig)
        break;
      }


      default:
        break;
    }
  }

  private snackBar = inject(MatSnackBar);


}


