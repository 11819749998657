import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { BaseComponent } from 'src/app/core/components/base-component.class';

@Component({
  selector: 'app-user-guide',
  templateUrl: './user-guide.component.html',
  styleUrls: ['./user-guide.component.scss'],
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule]
})
export class UserGuideComponent extends BaseComponent{

  hasDisplayedUserGuide = Boolean(localStorage.getItem('hasDisplayedUserGuide')) || false;

  updateDisplayUserGuide(){
    localStorage.setItem('hasDisplayedUserGuide', 'true');
  }

}
