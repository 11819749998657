import { Location } from "@angular/common";
import { Component, EventEmitter, OnInit, Output, inject, signal } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import { AuthService } from "src/app/auth/services/auth.service";
import { UserGuideComponent } from "src/app/home/components/user-guide/user-guide.component";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit{

  private authService = inject(AuthService);
  private dialog = inject(MatDialog);

  userName = signal(localStorage.getItem("name")!);
  profileUrl = signal(localStorage.getItem("profilePictureUrl")!);
  showInitial = signal<boolean>(false);

  @Output() menuClick = new EventEmitter<boolean>(true);

  ngOnInit(): void {
    this.profileUrl() === 'assets/images/no_profile.png' ? this.showInitial.set(true) : this.showInitial.set(false);
  }

  onSignOut(){
    this.authService.signOut();
  }

  openUserGuide(){
    this.dialog.open(UserGuideComponent);
  }
}
