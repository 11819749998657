<div class="flex flex-col gap-1 relative">
  <label [for]="placeholder"  class=" text-xs font-medium" *ngIf="label">{{label}}</label>
  <mat-form-field appearance="outline" [ngClass]="['form-field-wrapper', bgClass, hasError ? 'error-outline':'']">
    <ng-container *ngIf="type === textType.PASSWORD || type === textType.SEARCH; else textField">
      <div class="flex align-center" *ngIf="type === textType.PASSWORD">
        <input [formControl]="inputFormControl" matInput [placeholder]="placeholder" [name]="name" [type]="hide ? 'password' : 'text'" (input)="onInputValueChange($event)">
      <div class="flex align-center px-1">
          <mat-icon (click)="hide = !hide" class="text-primary cursor-pointer">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
      </div>
      </div>
      <div class="flex align-center" *ngIf="type === textType.SEARCH">
        <input [formControl]="inputFormControl" matInput [placeholder]="placeholder" [name]="name" [type]="type" (input)="onInputValueChange($event)">
      <div class="flex align-center px-1">
        <img src="/assets/images/search.svg">
      </div>
      </div>
    </ng-container>
    <ng-template #textField>
      <ng-container *ngIf="type === textType.TEXT_AREA; else textInput">
        <textarea [formControl]="inputFormControl" matInput [placeholder]="placeholder" [name]="name" [readonly]="readOnly" (input)="onInputValueChange($event)"></textarea>
      </ng-container>
      <ng-template #textInput>
        <input [formControl]="inputFormControl" matInput [placeholder]="placeholder" [name]="name" [type]="type" [readonly]="readOnly" (input)="onInputValueChange($event)">
      </ng-template>
    </ng-template>
  </mat-form-field>
  <p class="text-red-600 text-xs absolute error-text">
    <ng-content #content></ng-content>
  </p>
</div>
