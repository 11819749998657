<app-grid-layout-template backgroundImageUrl="/assets/images/drugs-bg.png">
  <div class="text-center text-white">
    <!-- <div>SIGN IN</div> -->
    <div  class="text-4xl font-light">Verification</div>
    <p class="mt-4 font-light">
      Please enter the otp sent to your registered email
    </p>
  </div>
  <form class="flex flex-col gap-4 mt-4 w-full mx-auto" [formGroup]="otpForm" (ngSubmit)="onSubmit()">
    <div class="flex justify-center items-center">
      <app-text-input class="w-full text-white" label="OTP" placeholder="Enter OTP" formControlName="otp" [hasError]="checkErrorMessage(otpForm, 'otp', isSubmitted())">
        <ng-container *ngIf="checkErrorMessage(otpForm, 'otp', isSubmitted())">
          <p *ngIf="otpForm.controls['otp'].errors!['required']">
            OTP Required.
          </p>
          <p *ngIf="otpForm.controls['otp'].errors!['pattern']">
            Invalid OTP.
          </p>
        </ng-container>
      </app-text-input>
    </div>
    <footer>
      <div class="my-4 text-center text-white text-sm">
        00:{{timeLeft}}
      </div>
      <button class="rounded w-full flex justify-center items-center my-2 py-2 font-medium bg-primary_dark" type="submit">
        <div class="flex-1 text-center text-white">VERIFY</div>
      </button>

      <div class="flex justify-center items-center">
        <div class="flex-1 text-center text-white font-light text-xs my-2">
          <span>If you didn't receive a code!</span><a (click)="resendOtp()" class="ml-2 text-white font-medium underline">Resend</a>
        </div>
      </div>
    </footer>

</form>
</app-grid-layout-template>
