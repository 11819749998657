<div class="home-container">
  <div class="absolute top-4 right-4 z-10">
    <button mat-mini-fab class="text-white" title="Help" (click)="openHelp()">
      <mat-icon >help</mat-icon>
    </button>
  </div>
  <app-grid-layout-template [backgroundImageUrl]="bgImageUrl">
    <div class="flex flex-col gap-4 mt-8 w-full mx-auto">
      <div class="rounded flex justify-center items-center bg-white mb-2 py-2 font-semibold cursor-pointer" [routerLink]="[ '/vhs']" [auth]="uiAccessList.Vhs">
          <div class="flex-1 text-center">VHS LABORATORY</div>
          <div class="px-4">
              <img src="/assets/images/landing-lab.svg" alt="Logo" class="h-8 w-8">
          </div>
      </div>

      <div class="rounded flex justify-center items-center bg-white mb-2 py-2 font-semibold cursor-pointer" [routerLink]="['/insurance']" [auth]="uiAccessList.Insurance">
          <div class="flex-1 text-center">INSURANCE</div>
          <div class="px-4">
              <img src="/assets/images/insurance-home.svg" alt="Logo" class="h-8 w-8">
          </div>
      </div>

      <div class="rounded flex justify-center items-center bg-white mb-2 py-2 font-semibold cursor-pointer" [routerLink]="[ '/document-scanner']" [auth]="uiAccessList.MedicalRecord">
        <div class="flex-1 text-center">MEDICAL RECORD UPLOAD</div>
        <div class="px-4">
            <img src="/assets/images/landing-upload.svg" alt="Logo" class="h-8 w-8">
        </div>
    </div>

      <div class="rounded flex justify-center items-center bg-white mb-2 py-2 font-semibold cursor-pointer" [routerLink]="['/upload-report']" [auth]="uiAccessList.UploadReport">
          <div class="flex-1 text-center">UPLOAD REPORT</div>
          <div class="px-4">
              <img src="/assets/images/landing-upload.svg" alt="Logo" class="h-8 w-8">
          </div>
      </div>

      <div class="rounded flex justify-center items-center bg-white mb-4 py-2 font-semibold cursor-pointer" [routerLink]="['/report']">
          <div class="flex-1 text-center text-primary">ENTER MANUALLY</div>
          <div class="px-4">
              <img src="/assets/images/landing-manually.svg" alt="Logo" class="h-8 w-8">
          </div>
      </div>
      <div class="flex justify-around">
          <div class="flex flex-col gap-1 justify-center items-center cursor-pointer" [routerLink]="['/user/profile']" [auth]="uiAccessList.Profile">
              <img src="/assets/images/landing-profile.svg" alt="profile" class="w-5 h-5">
              <div class="text-xs text-white">Profile</div>
          </div>
          <div class="flex flex-col gap-1 justify-center items-center cursor-pointer" [routerLink]="['/old-reports']" [auth]="uiAccessList.OldReports">
              <img src="/assets/images/landing-old-record.svg" alt="old reports" class="w-5 h-5">
              <div class="text-xs text-white">Old Reports</div>
          </div>
          <div class="flex flex-col gap-1 justify-center items-center cursor-pointer" [routerLink]="['/prescription']" [auth]="uiAccessList.Prescriptions">
            <img src="/assets/images/prescription.svg" alt="home" class="w-5 h-5">
            <div class="text-xs text-white">Prescription</div>
        </div>
        <div class="flex flex-col gap-1 justify-center items-center cursor-pointer" [routerLink]="['/comparison']" [auth]="[uiAccessList.ComparisonTable, uiAccessList.ReportComparison]">
          <img src="/assets/images/compare.svg" alt="home" class="w-10 h-5">
          <div class="text-xs text-white">Comparison</div>
      </div>
      </div>
      <div class="flex justify-center items-center text-xs gap-2 text-white cursor-pointer" (click)="onLogOut()">
          Logout
          <img src="/assets/images/landing-logout.svg" alt="logout" class="w-5 h-5">
      </div>
  </div>
  </app-grid-layout-template>
</div>
