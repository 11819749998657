import { Injectable, inject } from "@angular/core";
import { BaseStructure } from "src/app/core/interfaces/base-structure.model";
import { BaseService } from "src/app/core/services/base-service.class";
import { Gender } from "src/app/shared/constants/gender.enum";
import { SelectOption } from "src/app/shared/models/select-option.model";
import { ValueFlag } from "src/app/smart-report/enums/value-flag.enum";
import { AgeGroup } from "../constants/age-group.enum";
import { normalValue, numberUnits, otmNormalValues, abnormalValue, abnormalResultTypeValues } from "../constants/result-type.const";
import { ResultType } from "../constants/result-types.enum";
import { ModifiedInputData, RequestReportData } from "../models/modified-input-data.model";
import { CdssResultTypeOtm, Parameter, ResultTypeValues } from "../models/parameter.model";
import { PatientInfo } from "../models/patient-info.model";
import { ProfileInputParams, TestInputFormData } from "../models/report-form-input.model";
import { ReportGraph } from "../models/report-graph.model";
import { ReportMappingService } from "./report-mapping.service";
import { ReportService } from "./report.service";
import { ValueMapperService } from "./value-mapper.service";
import { Utils } from "src/app/core/utils/utils.class";
import { ReportUtils } from "../utils/report-utils.class";
import { DurationPeriod } from "src/app/shared/constants/duration-period-type.enum";

@Injectable({ providedIn: 'root' })

export class ReportFormatService extends BaseService{

  reportMappingService = inject(ReportMappingService);
  valueMapperService = inject(ValueMapperService);
  reportService = inject(ReportService);


  uploadedReportData: RequestReportData | undefined;

  async toApiFormat(basicInfo: PatientInfo, inputData: TestInputFormData[], isSmartReportRequired:boolean = true) {
    let finalData: RequestReportData = {
      gender: basicInfo.gender,
      age: basicInfo.age,
      actualAge:basicInfo.actualAge,
      country: basicInfo.country,
      actualParameters: [],
      parameters: [],
      isSmartReportRequired: isSmartReportRequired
    };
    let parmArr: ModifiedInputData[] = [];

    inputData.forEach(data => {
      let tempObj: ModifiedInputData = {};
      tempObj.parameter= data.parameter;
      tempObj.value = data.value;
      tempObj.isCanBeValidMinmax = data.isCanBeValidMinmax ?? false;
      tempObj.subSymptomValues = data.subSymptomValues ?? [];
      if (data.parameter.testId != null) {
        tempObj.testId = data.parameter.testId;

        if (data.parameter.resultType == ResultType.MIN_MAX) {
          tempObj.range = data.value.range;
          tempObj.unit = data.value.unit;
          tempObj.value = data.value.value;

        } else if (data.parameter.resultType == ResultType.OTM) {
          tempObj.value = data.value.map((selectedChip: SelectOption) => selectedChip.value)
        } else if(data.parameter.canBeMinmax && data.isCanBeValidMinmax){
          tempObj.range = data.value.range;
          tempObj.unit = data.value.unit;
          tempObj.value = data.value.value;
        }
      } else {
        if(!!data.parameter.symptomId){   //For symptoms
          tempObj.symptomId = data.parameter.symptomId;
          tempObj.duration = data.duration
          tempObj.actualDuration = data.actualDuration;
        }
      }
      parmArr.push(tempObj)
    }
    )
    finalData.actualParameters = parmArr.map((param:ModifiedInputData) => {
      const newObj = { ...param };
      delete newObj["parameter"];
      return newObj;
    });
    const normalizedValues = this.reportMappingService.normalizeValues(parmArr, basicInfo);
    let errNormalizedValues: ModifiedInputData[] = [];
   // finalData.parameters = normalizedValues.data;
   normalizedValues.data.forEach(test=>{
    test.actualTestName = test.parameter?.name;
    delete test['parameter'];
   })
    finalData.parameters = normalizedValues.data;
    return {payload:finalData, error:normalizedValues.error, errMarginNormalizedValues:errNormalizedValues};

  }

  async toLocalFormat(data: RequestReportData) {
    const parameters = data.actualParameters ?? data.parameters;
    let testParams:Parameter[];
    let symptomParams:Parameter[];

    if (parameters.length === 0) {
      return []; // Return an empty array if there are no parameters.
    }

    const testParmIds: any[] = parameters
      .map(parameter => ({ id: parameter.testId }))
      .filter(parameter => parameter.id != null);

    const symptomParmIds: any[] = parameters
      .map(parameter => ({ id: parameter.symptomId }))
      .filter(parameter => parameter.id != null);

    testParams = await this.getParams(testParmIds, 'cdss-test', ['testProfile']);
    symptomParams = await this.getParams(symptomParmIds, 'symptom', []);

    return new Promise<TestInputFormData[]>((resolve, reject) => {
      const tempParameters: Parameter[] = [...testParams, ...symptomParams];
      const inputData: TestInputFormData[] = [];
      tempParameters.forEach(param => {
        let tempObj: any = { parameter: param, value: null, isCanBeValidMinmax: false};
        if (param.testId != null) {
          tempObj.profile = this.getProfileName(param.testProfile)
          const tempParam = parameters.find(parameter => parameter.testId == param.testId);
          const tempValue = tempParam?.value;
          tempObj.value = this.valueMapperService.mapValueWithCode(param.resultType!, tempValue);
          tempObj.isMissMatch = tempParam?.isMissMatch;
          tempObj.matchPercentage = tempParam?.matchPercentage;
          tempObj.sNo = tempParam?.sNo;
          tempObj.isAuthorized = tempParam?.isAuthorized ?? true; //default value will be true, because of data from other source
          if(!!tempParam?.actualTestName){
            tempObj.parameter.name = tempParam?.actualTestName;
          }

          if (param.resultType == ResultType.OTM) {
            tempObj.value = this.getOtmValue(param.resultTypeValues ?? [], tempObj.value);
          } else if (param.resultType == ResultType.MIN_MAX) {
            const tempValue = parameters.find(parameter => parameter.testId == param.testId);
            const ptInfo:PatientInfo = {age: data.age, gender: data.gender as Gender, country: data.country, actualAge: data.actualAge}
            let finalValue = {
              value: tempValue?.value,
              range: !!tempValue?.range ? this.formatSymbolsFromRange(tempValue?.range) : this.findValidRange(param, ptInfo),
              unit: this.formatNumberUnits(tempValue?.unit!, param.resultTypeValues!),
            };
            tempObj.value = finalValue;
          }
          else if(param.canBeMinmax){
            const tempValue = parameters.find(parameter => parameter.testId == param.testId);
            const value = this.getCanBeMinmaxValue(tempValue?.value, tempValue?.range!, tempValue?.unit!);
            const isValidMinmax = ReportUtils.checkCanBeMinmaxType(value, param.canBeMinmax)
            if(isValidMinmax){
              const finalValue = {
                value: tempValue?.value,
                range: this.formatSymbolsFromRange(tempValue?.range ?? '-'),
                unit: tempValue?.unit,
              };
              tempObj.value = finalValue;
              tempObj.isCanBeValidMinmax = true;
            }
          }
        }
        else {

          const symptom = parameters.find(parameter => parameter.symptomId == param.symptomId)!;
          tempObj.value = this.valueMapperService.mapValueWithCode(ResultType.PRESENT_ABSENT, symptom.value);
          tempObj.duration = symptom.duration;
          tempObj.isAuthorized = true; //default value will be true, because of data from other source
          tempObj.subSymptoms = symptom.subSymptomValues ?? [];
          tempObj.profile = 'Symptoms';
          tempObj.isMissMatch = symptom.isMissMatch;
          tempObj.matchPercentage = symptom.matchPercentage;
          tempObj.actualDuration = symptom.actualDuration ?? {value:1, period:DurationPeriod.DAY}
        }
        inputData.push(tempObj);
      });

    resolve(inputData); // Resolve the promise with the inputData array.
    })
  }


  async getParams(ids:{id:number}[], path:string, relation:string[]){
    if(ids.length>0){
    const response = await this.reportService.findParmById(`${path}/find-all-and-count-by-admin`, { where: ids, relations: relation });
    let params  = response.status ? response.data?.[0]! : [];
    if(params.length>0){
      if(path === 'cdss-test'){
        return params.map(param=>{return {...param, testId: param.id}});
      } return params.map(param=>{return {...param, symptomId: param.id, resultType: ResultType.PRESENT_ABSENT, testProfile:[{name: 'Symptoms'}]}});
    }}
    return [];
  }


  getOtmValue(options: ResultTypeValues[], input: number[] | string | string []) {
    if(typeof input === 'string') input = input.split(',').map(value=>value.toLowerCase());
    if(Array.isArray(input) && input.length>0){
      let tempValue:SelectOption[] = options.flatMap((test: any) => [
        test.cdssTestResultTypeOtm,
      ])
        .flat()
        .map((option: CdssResultTypeOtm) => {
          return { label: option.name.toLowerCase(), value: option.id };
        });
      if(isNaN(+input[0])){
        const ocrInput:string[] = input as string[];
        const filteredValues = tempValue.filter((val: SelectOption) => ocrInput.includes(val.label)) ?? [];
        return this.formatOtmLabels(filteredValues);
      }

      const engineInput = input.map(v=>+v); //convert string into number
      const filteredValues = tempValue.filter((val: SelectOption) => engineInput.includes(+val.value)) ?? [];
      return this.formatOtmLabels(filteredValues);

    } return [];

  }

  findValidOption(basicInfo:PatientInfo, resultValues:ResultTypeValues[]){
    const hasElder = resultValues?.some(resultType=>resultType.ageGroup.includes(AgeGroup.ELDERLY)) && this.reportMappingService.findCategoryByAge(basicInfo.age) === 'ELDERLY';
     const selectedOption = resultValues.find(result=> this.reportMappingService.checkValidInput(basicInfo, result, hasElder));
     return selectedOption ?? resultValues[0];
  }

  findValidRange(parameter:Parameter, ptInfo:PatientInfo){
    if(!parameter.resultTypeValues?.length){
      return 'No range found';
    }
    const hasElder = parameter.resultTypeValues?.some(resultType=>resultType.ageGroup.includes(AgeGroup.ELDERLY)) && this.reportMappingService.findCategoryByAge(ptInfo.age) === 'ELDERLY';
    const validResultType = parameter.resultTypeValues?.find(resultType=> this.reportMappingService.checkValidInput(ptInfo, resultType, hasElder));
    return validResultType ? `${validResultType.cdssTestResultTypeMinMax?.min}-${validResultType.cdssTestResultTypeMinMax?.max}` : 'No range found';
  }

  getGraphInput(parametersArray: ModifiedInputData[]): ReportGraph[] {
    let reportGraphInput: ReportGraph[] = [];
    parametersArray.forEach((parameter, index) => {
        if (!!parameter?.range) {
          const graphInput: ReportGraph = {
            testName: parameter.actualTestName!,
            value: parameter.value,
            valueRange: this.checkValueRange(parameter.range!, +parameter.value, parameter.hasValueNormalized!)
          };
          reportGraphInput.push(graphInput);
        }
      });
    return reportGraphInput;
  }

  checkValueRange(range: string, value: number, hasValueNormalized: boolean): 'normal' | 'borderline' | 'high' | 'low' {
    const [min, max] = this.splitValueRange(range);
    if (hasValueNormalized) {
      return 'borderline'
    }
    else if (value < min) {
      return 'low';
    } else if (value >= min && value <= max) {
      return 'normal';
    } else {
      return 'high';
    }
  }

  getTestFlag(resultType: ResultType = ResultType.PRESENT_ABSENT, value: any, testInput: ModifiedInputData) {
    if (resultType === ResultType.MIN_MAX || !!value.range && testInput.isCanBeValidMinmax) {
      const testValueRange = this.getMinMaxFlag(value, testInput);
      return testValueRange;
    } else{
      const inputValue = value.value;
      if (resultType === ResultType.OTM) {
        const negativeValues = otmNormalValues;
        const hasNormalValue = inputValue.some((val: string) => negativeValues.includes(val.toLowerCase()));
        if (hasNormalValue) return ValueFlag.Normal;
        return ValueFlag.Abnormal;
      } else if(resultType===ResultType.REACTIVE_NONREACTIVE){
        return ValueFlag.Nil;
      }
      else {
        const options = resultType.split('_');
        if (inputValue == options[0] || abnormalResultTypeValues.includes(inputValue.toString())) {
          return ValueFlag.Abnormal;
        } else {
          return ValueFlag.Normal;
        }

      }
    }

  }

  getMinMaxFlag(input: {value:number, range:string, unit:string}, testInput:ModifiedInputData): ValueFlag {
      const [min, max] = this.splitValueRange(input.range);
    if (testInput.hasValueNormalized) {
      return this.getBorderLineFlag(min, max, input.value);
    }
    else if (input.value < min) {
      return testInput.isCanBeValidMinmax ? ValueFlag.Abnormal : ValueFlag.Low;
    } else if (input.value >= min && input.value <= max) {
      return  ValueFlag.Normal;
    } else {
      return testInput.isCanBeValidMinmax ? ValueFlag.Abnormal : ValueFlag.High;
    }
  }

  getBorderLineFlag(min: number, max: number, value: number): ValueFlag {
    const diff1 = Math.abs(value - min);
    const diff2 = Math.abs(value - max);

    if (diff1 < diff2) {
      return ValueFlag.Border_Low;
    } else {
      return ValueFlag.Border_High;
    }
  }


  getProfileName(profiles:BaseStructure[]=[]){
    return profiles.length ? profiles[0].name : 'Others';
  }

  groupParamsByProfile(inputData: TestInputFormData[]): ProfileInputParams[] {
    const hasSNo = inputData[0].sNo || false;
    if(hasSNo){
      return this.groupProfileBySerialNumber(inputData);
    }
    return this.groupTestByProfile(inputData);

  }

  groupTestByProfile(inputData: TestInputFormData[]): ProfileInputParams[] {
      let outputArray: ProfileInputParams[] = [];
      inputData.forEach((item, index:number) => {
        item.tempId = index;
        const existingProfile = outputArray.find(profile => profile.profile === item.profile);

        if (existingProfile) {
          existingProfile.params.push(item);
        } else {
          outputArray.push({
            profile: item.profile,
            params: [item]
          });
        }
      });

      outputArray = this.orderProfileList(outputArray);
      return outputArray;
  }

  groupProfileBySerialNumber(inputData: TestInputFormData[]): ProfileInputParams[] {
    inputData = this.sortBySNo(inputData);
    inputData = inputData.map((data, index)=>{return{...data, tempId:index}});
    const uniqueProfiles = [...new Set(inputData.map(item => item.profile))];
    let groupedParams: ProfileInputParams[] = uniqueProfiles.map(profile => {
      const paramsForProfile = inputData.filter(item => item.profile === profile);
      const sortedParams = this.sortBySNo(paramsForProfile)
      return { profile, params: sortedParams };
    });
    groupedParams = this.orderProfileList(groupedParams);
    return groupedParams;
  }

private sortBySNo(testInputArray:TestInputFormData[]):TestInputFormData[]{
  testInputArray.sort((a, b) => a.sNo! - b.sNo!);
  const params = testInputArray;
  return params;
}

  formatSymbolsFromRange(refRange:string){
    refRange = refRange.trim();
    const refValue = (refRange.match(/\d+(\.\d+)?/) || ['0'])[0];

    if (refRange.startsWith('<=') || refRange.startsWith('</=') || refRange.startsWith('<') || /less\s*than/i.test(refRange)) { //starts with less than
      refRange = `0 - ${refValue}`;
    }
    else if (refRange.startsWith('>=') || refRange.startsWith('>/=') || refRange.startsWith('>') || /greater\s*than/i.test(refRange)) { //starts with greater than
      const maxValue = Math.pow(+refValue, 5);
      refRange = `${refValue} - ${maxValue}`;
    }
    return refRange;
  }

  formatNumberUnits(unit: string, resultTypeValues: ResultTypeValues[]){
    const units = resultTypeValues.map(result=> result.cdssTestResultTypeMinMax?.unitName.name!);
    const foundUnits = units.filter(item => numberUnits.includes(item));
    if(foundUnits.length) return foundUnits[0];
    return unit;
  }

  formatOtmLabels(otms:SelectOption[]){
    if(!otms.length) return [];
    return otms.map(value=>{return{value: value.value, label: Utils.capitalizeFirstLetter(value.label)}});
  }

  getCanBeMinmaxValue(value: number|string, range: string, unit:string){
    const hasValidUnitRange = !!range && !!unit;
    if(hasValidUnitRange){
      return {value: value, range: range, unit: unit};
    } return value;
  }

  orderProfileList(profileInputParamsArray: ProfileInputParams[]): ProfileInputParams[] {
    // Step 1: Find index of "Others" profile and move it to the end
    const othersIndex = profileInputParamsArray.findIndex(item => item.profile === "Others");
    if (othersIndex !== -1) {
      const othersProfile = profileInputParamsArray.splice(othersIndex, 1)[0];
      profileInputParamsArray.push(othersProfile);
    }

    // Step 2: Find index of "Symptoms" profile and move it to the end (if not already there)
    const symptomsIndex = profileInputParamsArray.findIndex(item => item.profile === "Symptoms");
    if (symptomsIndex !== -1 && symptomsIndex !== profileInputParamsArray.length - 1) {
      const symptomsProfile = profileInputParamsArray.splice(symptomsIndex, 1)[0];
      profileInputParamsArray.push(symptomsProfile);
    }

    return profileInputParamsArray;
  }



  getDurationValue(input:{value: number, period: DurationPeriod}) {
    const { value, period } = input;
    let hours = 0;
    if(value == 0) return hours;

    switch (period) {
      case DurationPeriod.DAY:
        hours = value * 24;
        break;
      case DurationPeriod.WEEK:
        hours = value * 24 * 7;
        break;
      case DurationPeriod.MONTH:
        hours = value * 24 * 30; // Approximate, assuming a month has 30 days
        break;
      case DurationPeriod.YEAR:
        hours = value * 24 * 365; // Approximate, assuming a year has 365 days
        break;
      case DurationPeriod.HOUR:
        hours = value;
        break;
      default:
        break;
    }

    return this.findMatchingValue(hours);
  }

   private findMatchingValue(hours:number) {
    if (hours < 2 * 24) return 1; //less than 2 days
    else if (hours <= 2 * 24 * 7) return 2; //upto 2 weeks
    else if (hours <= 6 * 24 * 7) return 3; //upto 6 weeks
    else return 4; // More than 6 weeks
  }

  /**
   * input: -2-+3, output: [-2, 3]
   * input: 2-3, output: [2,3]
   * @param range
   * @returns [min, max]
   */
  private splitValueRange(range:string){
    const dashIndices = [];
    let lastIndex = -1;

    while ((lastIndex = range.indexOf('-', lastIndex + 1)) !== -1) {
        dashIndices.push(lastIndex);
    }

    let part1, part2;
    if (dashIndices.length >= 2) {
        // If there are at least two dashes, split at the second one
        part1 = range.slice(0, dashIndices[1]).trim();
        part2 = range.slice(dashIndices[1] + 1).trim();
    } else if (dashIndices.length === 1) {
        // If there is only one dash, consider it as the second
        part1 = range.slice(0, dashIndices[0]).trim();
        part2 = range.slice(dashIndices[0] + 1).trim();
    } else {
        // If there are no dashes, the whole string is part1, part2 is empty
        part1 = range.trim();
        part2 = "";
    }
    return [+part1, +part2]
  }

}


