<article class="px-8 py-4 upload-symptom-container">
  <h2 class="text-xl font-bold">Add Symptoms</h2>
  <section class="mt-8 max-h-72 overflow-y-auto">
    <app-upload-symptom-table [dataList]="selectedSymptoms" [columns]="displayColumns"
    [buttons]="buttons" (action)="onTableAction($event)"></app-upload-symptom-table>
  </section>
  <form class="mt-8" [formGroup]="symptomForm">
    <div class="flex gap-4">
      <app-auto-complete-input formControlName="parameter" (searchTerm)="onSymptomSearch($event)" (onValueChange)="setSymptom()" [hasError]="checkErrorMessage(symptomForm, 'parameter', isSubmitted())"
      [options]="symptomOptions" placeholder="Search Symptom">
      <ng-container *ngIf="checkErrorMessage(symptomForm, 'parameter', isSubmitted())">
        <p *ngIf="formControl['parameter'].errors!['required']">
          Parameter required.
        </p>
      </ng-container>
      </app-auto-complete-input>
      <app-duration-input class="w-48" formControlName="actualDuration" (onValueChange)="setDurationValue()"
      [hasError]="checkErrorMessage(symptomForm, 'duration', isSubmitted())">
        <ng-container *ngIf="checkErrorMessage(symptomForm, 'duration', isSubmitted())">
          <p *ngIf="formControl['duration'].errors!['required']">
            Duration required.
          </p>
        </ng-container>
      </app-duration-input>
      <!-- <button mat-stroked-button color="primary" *ngIf="selectedSymptom?.subSymptoms?.length">More Details</button> -->
      <button mat-flat-button color="primary" (click)="addSymptom()">Add</button>
    </div>
  </form>
  <footer class="flex justify-end gap-4 mt-12">
    <button mat-stroked-button color="primary" [mat-dialog-close]="[]">Discard</button>
    <button mat-flat-button color="primary" (click)="onSubmit()">Submit</button>
  </footer>
</article>
