import { HttpEventType } from '@angular/common/http';
import { Component, inject, signal } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseComponent } from 'src/app/core/components/base-component.class';
import { DurationPeriod } from 'src/app/shared/constants/duration-period-type.enum';
import { HasUnsavedChanges } from 'src/app/shared/models/hasUnSavedChanges.interface';
import { MessageType } from 'src/app/shared/models/message-type.enum';
import { ModifiedInputData, RequestReportData } from '../../models/modified-input-data.model';
import { ReportService } from '../../services/report.service';
import { MatDialog } from '@angular/material/dialog';
import { UploadSymptomsComponent } from '../upload-symptoms/upload-symptoms.component';
import { TestInputFormData } from '../../models/report-form-input.model';
import { duration } from 'moment';

@Component({
  selector: 'app-upload-report',
  templateUrl: './upload-report.component.html',
  styleUrls: ['./upload-report.component.scss']
})
export class UploadReportComponent extends BaseComponent implements HasUnsavedChanges {

  protected reportService = inject(ReportService);
  protected dialog = inject(MatDialog);


  protected files: File[] = [];
  protected isSubmitted = signal(false);
  protected progressValue = signal(0);
  protected selectedSymptoms: TestInputFormData[] = [];
  protected isDialogOpened = signal(false);
  protected responseData: RequestReportData | undefined;

  onFileChange(currentlySelectedFiles: File[]) {
    if (currentlySelectedFiles.length) {
      const currentlySelectedFilesLength = currentlySelectedFiles.length;
      const hasMultiplePdf = currentlySelectedFiles.every((file) => file.type === 'application/pdf');
      const hasExistingPdf = this.files.length && this.files[0].type === 'application/pdf';
      const hasMultipleFilesWithPdf = currentlySelectedFilesLength > 1 && hasMultiplePdf;

      const hasMultiplePdfFilesSelected = hasMultipleFilesWithPdf || hasExistingPdf
      if (hasMultiplePdfFilesSelected) {
        this.files = [currentlySelectedFiles[0]];
        this.notificationService.notify("You can't select multiple pdf files", MessageType.INFO, "Failed!");
        return;
      }
      this.updateFiles(currentlySelectedFiles);
    }
  }

  onFileSelected(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const files: File[] = Array.from(inputElement.files ?? []);
    this.onFileChange(files);
  }

  hasUnsavedChanges(): boolean | Observable<boolean> {
    const hasFiles = this.files.length > 0 && !this.isSubmitted();
    return hasFiles;
  }

  updateFiles(files: File[]) {
    const fileTypes = files.map(file => file.type);
    const allowedImageTypes = ['image/jpeg', 'image/png'];
    const allowedPdfTypes = ['application/pdf'];
    const allowedFileTypes = [...allowedImageTypes, ...allowedPdfTypes];
    const hasSameType = fileTypes.every(type => type === fileTypes[0]);
    const validExtensions = fileTypes.some((type) => allowedFileTypes.includes(type));
    if (!hasSameType) {
      const requiredFileType = allowedImageTypes.includes(fileTypes[0]) ? 'image files' : 'PDF file';
      this.notificationService.notify(`Please select ${requiredFileType}`, MessageType.INFO, 'Failed!');
      return;
    } else if (!validExtensions) {
      this.notificationService.notify('Please select JPG, PNG or PDF file type ', MessageType.INFO, 'Failed!');
      return;
    } else {
      const existingFileTypes = this.files.map(file => file.type);
      const hasExistingFileType = existingFileTypes.every(type => type === fileTypes[0]);
      if (!hasExistingFileType) {
        this.notificationService.notify('Please select same type of files', MessageType.INFO, 'Failed!');
        return;
      } else {
        const selectedFiles = [...this.files, ...files];
        this.files = this.removeDuplicateFiles(selectedFiles);
      }
    }
  }

  removeDuplicateFiles(files: File[]): File[] {
    if (files.length > 1) {
      const uniqueFiles: { [key: string]: File } = {};
      for (const file of files) {
        uniqueFiles[file.name] = file;
      }
      return Object.values(uniqueFiles);
    } return files;
  }

  removeFile(index: number) {
    this.files.splice(index, 1);
  }

  async onUpload(uploadFailedResponseCount = 0) {
    this.isSubmitted.set(true);
    if (this.files.length) {
      let body: FormData = new FormData;
      this.files.forEach((file) => body.append('files', file, file.name));
      this.subscription.add(
        this.reportService.postReportImage(body)
          .subscribe({
            next: (event: any) => {
              switch (event.type) {
                case HttpEventType.UploadProgress:
                  this.progressValue.set(Math.round(event.loaded / event.total! * 100));
                  break;
                case HttpEventType.Response:
                  const response = event.body//this.convertData(event.body);
                  if (response.status) {
                    this.isResponseReceived = true;
                    this.handleResponse(response);
                  } else {
                    this.handleResponseError(uploadFailedResponseCount);
                  }
              }
            },
            error: (err: any) => {
              this.files = [];
              this.isSubmitted.set(false);
              this.progressValue = signal(0);
            }
          })
      )
    } else {
      this.notificationService.notify("Files required", MessageType.ERROR, "Failed!")
    }


    /**Uncomment below code if want to test with dummy ocr response */

    // this.reportService.getDummy().subscribe((res:any)=>{
    //   this.router.navigateByUrl('/report',{state:{data:this.formatOCRData(res)}})
    // })
  }

  private handleResponse(response: any) {
    const responseData = response?.data ?? {};
    if (!responseData['country']) {
      responseData['country'] = 'India';
    }
    if (responseData['age']) {
      const age = Math.round(responseData['age']);
      responseData['age'] = age;
      responseData['actualAge'] = { "age": age, "period": DurationPeriod.YEAR }
    }
    if(responseData['confidence_score']){
      responseData['confidence_score'] = responseData['confidence_score'] * 100;
    }
    // const isEmptyData = (responseData["actualParameters"] ?? []).length == 0 && (responseData["miss_match"] ?? []).length == 0;
    // if (isEmptyData) {
    //   this.notificationService.notify('Unable to read this file, Please enter manually', MessageType.INFO, '', { duration: 10000 });
    //   this.isSubmitted.set(false);
    //   this.progressValue = signal(0);
    //   return;
    // }
    this.responseData = responseData;
    this.checkAndNavigateToNextPage();
  }

  private handleResponseError(uploadFailedResponseCount: number) {
    if (uploadFailedResponseCount === 0) {
      uploadFailedResponseCount = uploadFailedResponseCount + 1;
      this.onUpload(uploadFailedResponseCount);
    } else {
      this.notificationService.notify('Unable to process this file', MessageType.ERROR, 'Failed!');
      this.isSubmitted.set(false);
      this.progressValue = signal(0);
    }

  }

  private formatOCRData(inputData: RequestReportData, symptomInput: TestInputFormData[]): RequestReportData {
    const validParams = inputData.actualParameters?.map(param => { return { ...param, isMissMatch: false, matchPercentage: 100 } }) ?? [];
    const missMatchParams = inputData.miss_match?.map(res => { return { ...res, isMissMatch: true, matchPercentage: res.matchPercentage ?? 100 } }) ?? [];
    const formattedInputSymptoms = this.formatInputSymptoms(symptomInput);
    const actualParameters = [...validParams, ...missMatchParams, ...formattedInputSymptoms];
    inputData.actualParameters = actualParameters;
    return inputData;
  }

  addSymptoms() {
    const dialogRef = this.dialog.open(UploadSymptomsComponent, { data: this.selectedSymptoms, disableClose: true });
    this.isDialogOpened.set(true);
    this.subscription.add(
      dialogRef.afterClosed().subscribe((res: TestInputFormData[]) => {
        if (res.length > 0) {
          this.selectedSymptoms = res;
          this.isDialogOpened.set(false);
          this.checkAndNavigateToNextPage();
        }
      })
    )
  }

  private formatInputSymptoms(symptomInput: TestInputFormData[]): ModifiedInputData[] {
    if (!symptomInput.length) {
      return [];
    }
    const modifiedInput = symptomInput.map(symptom => {
      return {
        actualDuration: symptom.actualDuration,
        duration: symptom.duration,
        subSymptomValues: symptom.subSymptomValues ?? [],
        symptomId: symptom.parameter.id,
        value: symptom.value
      }
    })
    return modifiedInput;
  }

  private checkAndNavigateToNextPage() {
    if (!this.isDialogOpened()) {
      const responseData = this.responseData;
      if (responseData) {
        const formattedData = this.formatOCRData(responseData, this.selectedSymptoms);
        this.router.navigateByUrl('/report', { state: { data: formattedData } });
      }
    }
  }
}


