<form [formGroup]="inputForm" class="relative w-full">
  <label [for]="placeholder"  class=" text-xs font-medium" *ngIf="label">{{label}}</label>
  <div [ngClass]="['border', 'border-[#e5e7eb]', 'text-black', 'text-sm', 'rounded-md', 'flex', 'flex-nowrap', 'items-center', 'overflow-hidden','input-wrapper', hasError ? 'error-outline':'']" >
      <input class="p-2 w-6/12 border-[#EFEFEF] border-r-[1.5px] flex justify-end gap-2 items-center text-center" [ngClass]=" hasError ? 'error-border':''"
        formControlName="value" matInput [placeholder]="placeholder" [name]="placeholder" type="number" min="1" max="150" [readonly]="readOnly" (input)="onInputValueChange()">
        <mat-select  formControlName="period" class="p-2 flex flex-1 text-center bg-white" (selectionChange)="onInputValueChange()">
          <mat-option *ngFor="let option of periodOptions" [value]="option.value">{{option.label | titlecase}}</mat-option>
        </mat-select>
  </div>
  <p class="text-red-600 text-xs absolute error-text">
    <ng-content #content></ng-content>
  </p>
</form>
