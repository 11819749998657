import { SocialAuthService, SocialUser } from '@abacritt/angularx-social-login';
import { Component, OnInit, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { firstValueFrom } from 'rxjs';
import { BaseFormComponent } from 'src/app/core/components/base-form.class';
import { RoutePath } from 'src/app/core/constants/route.const';
import { AppUtitlityService } from 'src/app/core/services/utitlity.service';
import { ValidationPatterns } from 'src/app/core/utils/validation-patterns';
import { Country } from 'src/app/shared/models/country.model';
import { MessageType } from 'src/app/shared/models/message-type.enum';
import { SelectOption } from 'src/app/shared/models/select-option.model';
import { SignUp } from '../../models/sign-up.model';
import { AuthService } from '../../services/auth.service';
import { SocialAuthenticationService } from '../../services/social-auth.service';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent extends BaseFormComponent implements OnInit {
  bgImageUrl: string = '/assets/images/landing-bg.png';
  countryOptions: SelectOption[] = [];
  agreeToConditions:boolean = false;
  get formControl() {
    return this.signupForm.controls;
  }

  private socialAuthService = inject(SocialAuthService);
  appUtilityService = inject(AppUtitlityService);
  private socialService = inject(SocialAuthenticationService);
  private authService = inject(AuthService);


  signupForm = new FormGroup({
    firstName: new FormControl<string>('', [Validators.required, Validators.pattern(ValidationPatterns.text)]),
    lastName: new FormControl<string>('', [Validators.required, Validators.pattern(ValidationPatterns.text)]),
    email: new FormControl<string>('', [Validators.required, Validators.pattern(ValidationPatterns.email)]),
    countryCode: new FormControl<string>('', [Validators.required]),
    dob: new FormControl<string>(''),
    password: new FormControl<string>('', [
      Validators.required,
      Validators.pattern(ValidationPatterns.password)
    ]),
  });

  ngOnInit(): void {
    this.getCountryOptions();
    this.subscription.add(
      this.socialAuthService.authState.subscribe(async (user:SocialUser) => {
        const payload = {token: user.idToken, provider: user.provider.toUpperCase()};
        const response = await this.socialService.socialSignUp(payload);
        if(response.status){
          this.authService.setAccessToken(response.data!);
          this.notificationService.notify("Logged in successfully", MessageType.SUCCESS);
          this.router.navigateByUrl(RoutePath.home);
        }else{
          this.notificationService.notify(response.message, MessageType.ERROR);
        }
      })
    )
  }

  async onSubmit() {
    this.isSubmitted.set(true);
    if (this.signupForm.valid) {
      let payload: SignUp = this.signupForm.value as SignUp;
      const response = await this.authService.signUp(payload);
      if (response.status) {
        this.router.navigateByUrl(RoutePath.verifyOtpNewUser, {state:{email:payload.email}});
      } else{
        this.notificationService.notify(response.message, MessageType.ERROR);
      }
    }
  }

  async getCountryOptions(){
    const countries = await firstValueFrom(this.appUtilityService.getCountriesList());
    this.countryOptions = countries.map((country:Country)=>{
      return {label:country.name, value:country.name}
    });
  }
}
