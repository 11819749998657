import { Component, Input, OnChanges, SimpleChanges, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { MultiFieldValue } from 'src/app/shared/models/multi-field-value.model';


@Component({
  selector: 'app-multi-field-input',
  templateUrl: './multi-field-input.component.html',
  styleUrls: ['./multi-field-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiFieldInputComponent),
      multi: true,
    },
  ],
})
export class MultiFieldInputComponent implements OnChanges,ControlValueAccessor{

  @Input() placeholder:string='';
  @Input() label:string='';
  @Input() readOnly:boolean=false;
  @Input() rangeOptions:any[]=[];
  @Input() unitOptions:any[]=[];
  @Input() value!:MultiFieldValue;
  @Input() hasError:boolean = false;

  propagateChange = (_: any) => {};
  touchChange = (_: any) => {};

  multiFieldForm = new FormGroup({
    value: new FormControl <number|undefined>(undefined, Validators.required),
    range: new FormControl <string>(''),
    unit: new FormControl <string>('')
  })

  ngOnChanges(changes:SimpleChanges): void {
    if(changes['rangeOptions'] || changes['unitOptions']){
      this.multiFieldForm.controls['range'].setValue(this.rangeOptions[0]);
      this.multiFieldForm.controls['unit'].setValue(this.unitOptions[0]);
    }
    if(changes['value'] && changes['readOnly'].currentValue===true){
      this.multiFieldForm.patchValue({
        ...this.value
      })
    }
    }

  writeValue(obj: any): void {
    if(obj){
      this.multiFieldForm.patchValue({
        ...obj
      })
      return;
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange=fn;
  }
  registerOnTouched(fn: any): void {
    this.touchChange=fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    //throw new Error('Method not implemented.');
  }

  getMultiFieldValue(){
    this.propagateChange(this.multiFieldForm.value)
  }
}
