export const UiAccessList = {
  GetParameters : [2801],
  SubmitParameters : [2802],
  GetSymptomsByDiseaseId : [2803],
  RuleEngineReport : [3507],
  AiReport : [3508],
  SmartReport : [3509],
  Report: [2804, 2805, 2806],
  Charts : [3510],
  OldReports : [3511],
  UploadReport : [3512],
  Prescriptions : [3513],
  DownloadSmartReport : [2811],
  ShareReport : [2812],
  Profile : [2813],
  Laboratory : [3000],
  ReportRating: 3501,
  DoctorDiagnosis : 3502,
  Diagnosis: 3503,
  RadiologyFinding : 3504,
  Medication : 3505,
  OtherHealthInfo : 3506,
  EngineReport : 3507,
  Vhs : 3514,
  EnableLog : 3515,
  PlanOfAction: 3516,
  PromptView: 3517,
  History: 3518,
  Examination: 3519,
  ComparisonTable: 3520,
  ReportComparison: 3521,
  DocumentScanner: 3522,
  MedicalRecord: 3523,
  Insurance: 3902,
  DocSegregation: 3903,
  InsurancePdfDownload: 3904
}
