import { Directive, EventEmitter, HostBinding, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[fileDragDrop]'
})
export class DragDropDirective {

  @Output() private filesChangeEmitter : EventEmitter<File[]> = new EventEmitter();

  @HostBinding('style.background') private background = '#fff';

  @HostListener('dragover', ['$event']) public onDragOver(e:DragEvent){
    e.preventDefault();
    e.stopPropagation();
    this.background = '#f5f5f5';
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(e:DragEvent){
    e.preventDefault();
    e.stopPropagation();
    this.background = '#fff'
  }

  @HostListener('drop', ['$event']) public onDrop(e:DragEvent){
    e.preventDefault();
    e.stopPropagation();
    this.background = '#fff';
    const files = e.dataTransfer!.files;
    if(files && files.length >1){
      return;
    }
    this.filesChangeEmitter.emit(Array.from(files));

  }

}
