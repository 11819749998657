<div class="bg-primary flex relative">
  <div class="flex flex-1 img-section">
    <div class="bg-primary_dark  flex ">
      <img [src]="backgroundImageUrl" class="w-full h-full">
    </div>
  </div>
  <div class="flex flex-1 items-center justify-center py-4 min-h-screen w-full">
    <div class="my-4">
      <div class="box rounded-2xl border border-[#5882C1]">
          <div class="flex gap-2 items-center justify-center mb-4" *ngIf="!hideLogo">
              <div class="flex items-center cursor-pointer" [routerLink]="'/auth/sign-in'">
                <img src="/assets/images/logo.svg" alt="Logo" class="w-14">
              <span class="text-white text-2xl font-bold">ALGOHEALTH</span>
              </div>
          </div>
          <div class="flex flex-col gap-4  w-full mx-auto content">
            <ng-content #content></ng-content>
          </div>
      </div>
  </div>

  </div>
</div>
