import { Injectable, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../components/dialog/dialog.component';
import { DialogData } from '../models/dialog.model';

@Injectable({providedIn: 'root'})
export class DialogService {

  private dialog = inject(MatDialog)

  openDialog(data:DialogData, width?:string){
    let payload:{data:DialogData, width?:string} = {data:data};
    if(width){
      payload['width'] = width
    }
    return this.dialog.open(DialogComponent, payload).afterClosed();
  }

}
