import { CanDeactivateFn } from "@angular/router";
import { lastValueFrom } from "rxjs";
import { DialogData } from "../models/dialog.model";
import { HasUnsavedChanges } from "../models/hasUnSavedChanges.interface";
import { DialogService } from "./dialog.service";

export const hasUnsavedChangesGuard: CanDeactivateFn<any> = (component: HasUnsavedChanges):boolean | Promise<boolean> => {
  const dialogService = new DialogService()
  const hasUnsavedChanges = component.hasUnsavedChanges();

  if (hasUnsavedChanges) {
    const dialogData:DialogData = {
      title: 'Leave Page?',
      content: 'Changes that you made may not be saved',
      button: {
        proceed: 'Leave',
        discard: 'Cancel',
        color: 'primary'
      }
    }
  // Return a Promise that resolves based on the dialog response
  return new Promise<boolean>((resolve) => {
   lastValueFrom( dialogService.openDialog(dialogData))
      .then((response) => resolve(response === true)) // Allow navigation only if 'proceed' is clicked
      .catch((error) => {
        console.error('Error opening dialog:', error);
        resolve(false); // Allow navigation on error (consider appropriate handling)
      });
  });
  }

  return true; // Allow navigation
}
