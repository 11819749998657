import { Injectable, OnDestroy, isDevMode } from '@angular/core';
import * as LogRocket from 'logrocket';
import { AccessService } from './access.service';
import { UiAccessList } from '../constants/ui-access-list.const';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/auth/services/auth.service';


@Injectable({
  providedIn: 'root',
})

/**
 *
 *
 * @export
 * @class LogService
 */
export class LoggerService implements OnDestroy{
  private isLogRocketStarted = false;

  /**
   * Creates an instance of LogService.
   * @param {NotificationService} notificationService
   * @memberof LogService
   */
  constructor(private accessService: AccessService, private authService: AuthService) {}

  initLogRocket(){
    /// if log rocket started then do nothing
    if (this.isLogRocketStarted){
      return;
    }
    
    const logAccess = UiAccessList.EnableLog || 3515;
    const hasLogAccess = this.accessService.checkAccess(logAccess);
    if (hasLogAccess ) {
      LogRocket.init(environment.logRocketToken);
      const token = this.authService.getDecodeToken();
      LogRocket.identify((token.id || '').toString(), { name: token.userName, email: token.userName });
      this.isLogRocketStarted = true;
    }
  }

  initLogger(){
    this.initLogRocket();
  }

  ngOnDestroy(){
    this.isLogRocketStarted = false;
  }

}
