import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { AuthService } from 'src/app/auth/services/auth.service';
import { AccessService } from './access.service';

export const UserResolver: ResolveFn<boolean> = (route, state) => {
  const authService = inject(AuthService);
  const accessService = inject(AccessService);
  const token = authService.getAccessToken();
  const tokenPayload = authService.decodeToken(token);
  accessService.setUserAccesses(tokenPayload.accessIds);
  return true;
};
