export const environment = {
  production: false,
  //API_URL: 'http://localhost:3000/',
  API_URL: 'http://ec2-3-139-218-111.us-east-2.compute.amazonaws.com:3000/',
  AUTH_URL: 'http://ec2-3-139-218-111.us-east-2.compute.amazonaws.com:3002/',
  ENGINE_URL: '',
  captchaSiteKey: '6LfhIjcnAAAAAL1ncAcgz3a8oW46bBZrA_BLMPxP',
  googleClientId: '840151798335-985b64bp751lmsqegu89ufh3m2dgdm5i.apps.googleusercontent.com',
  encryptionKey: 'AIgopubIicid',
  forInsurence: true,
  logRocketToken: 'uftzni/algo-health-public',
  idleLogoutTime: 100*60*1000, //10 Mins

};
