import { inject } from "@angular/core";
import { AppHttpService } from "./app-http.service";

export abstract class BaseService{

  protected httpService = inject(AppHttpService);


  getAccessName(path:string){
    const numOfSlashes = path.split('/').length - 1;
    if(numOfSlashes === 1){
      return path.replace('/', '~')
    }
    return '';
  }

}
