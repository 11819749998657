import { ResultType } from "./result-types.enum";

export const normalResultTypeValues = ['ABSENT', 'NEGATIVE', 'DECREASED', 'NONREACTIVE', 'NIL', '0', 'FALSE', 'NON-REACTIVE', 2, "2"];
export const abnormalResultTypeValues = ['PRESENT', 'POSITIVE', 'INCREASED', 'REACTIVE', '1', 'TRUE', 1];

export const abnormalValue = 1;
export const normalValue = 2;


//otm  normal values should be in lowercase
export const otmNormalValues = ['nil', 'absent', 'normal', 'negative', '>90', 'pale yellow', 'yellow', 'clear'];

export const numberUnits = ['Units', 'Ratio', 'Number', '%'];

export const otherResultTypes = [ResultType.MIN_MAX, ResultType.OTM];
