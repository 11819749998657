import { Component, Inject, OnInit, inject } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { DataTableIcons } from 'src/app/auth/constants/otp-path.const';
import { BaseFormComponent } from 'src/app/core/components/base-form.class';
import { Utils } from 'src/app/core/utils/utils.class';
import { DurationPeriod } from 'src/app/shared/constants/duration-period-type.enum';
import { SelectOption } from 'src/app/shared/models/select-option.model';
import { TableAction, TableButton, TableColumn } from 'src/app/shared/models/table.model';
import { ResultType } from '../../constants/result-types.enum';
import { Parameter } from '../../models/parameter.model';
import { TestInputFormData } from '../../models/report-form-input.model';
import { ReportFormatService } from '../../services/report-format.service';
import { ReportService } from '../../services/report.service';
import { UploadSymptomTableComponent } from '../upload-symptom-table/upload-symptom-table.component';
import { MessageType } from 'src/app/shared/models/message-type.enum';


@Component({
  selector: 'app-upload-symptoms',
  templateUrl: './upload-symptoms.component.html',
  styleUrls: ['./upload-symptoms.component.scss']
})
export class UploadSymptomsComponent extends BaseFormComponent implements OnInit{

  symptomOptions:SelectOption[] = [];
  symptomForm!:FormGroup;
  selectedSymptom: Parameter | undefined;
  selectedSymptoms:TestInputFormData[] = [];

  private reportService = inject(ReportService);
  private reportFormatService = inject(ReportFormatService);
  protected domSanitizer = inject(DomSanitizer);
  protected dialogRef = inject(MatDialogRef<UploadSymptomTableComponent>);

  constructor(@Inject(MAT_DIALOG_DATA) public data: TestInputFormData[]){
    super();
  }

  displayColumns: TableColumn[] = [
    {
      label: 'Symptom Name',
      key: 'symptomName',
    },
    {
      label: 'Duration',
      key: 'duration'
    }
  ]

  buttons:TableButton[] = [
    {
      iconUrl: this.domSanitizer.bypassSecurityTrustHtml(DataTableIcons.DELETE),
      name: 'delete',
    }
  ]


  get formControl() {
    return this.symptomForm.controls;
  }


  ngOnInit(): void {
    this.symptomForm=this.fb.group({
      parameter: ['', [Validators.required]],
      value: ['', [Validators.required]],
      duration: ['', [Validators.required]],
      actualDuration: [],
      profile: [],
      subSymptomValues: []
    })
    this.selectedSymptoms = this.data;
  }

  async onSymptomSearch(searchKey: string){
    searchKey = searchKey.trim();
    if(!searchKey){
      this.symptomOptions = [];
      return;
    }

    const payload = {
      where:[{name: searchKey}, {code: searchKey}],
      relations: ["aliases", "subSymptoms"]
    }

    const symptomResponse = await this.reportService.findSymptoms(payload);
   if(symptomResponse.status){
    const responseData:Parameter[] = symptomResponse.data?.[0] ?? [];
    const symptoms = responseData.map(symptom=>{
      return{
        ...symptom,
        symptomId: symptom.id,
        resultType: ResultType.PRESENT_ABSENT,
        testProfile:[{name: 'Symptoms'}]
      }
    })
    this.symptomOptions = symptoms.map(symptom=>{return{label:symptom.name, value: symptom, aliases: Utils.formatAliases(symptom.aliases ?? [])}})
   }

  }

  setSymptom(){
    this.selectedSymptom = this.formControl['parameter'].value;
    this.formControl['value'].setValue('1');
    const profile =  this.selectedSymptom?.testProfile[0].name;
    this.formControl['profile'].setValue(profile);
  }

  setDurationValue(){
    const actualDuration = this.formControl['actualDuration'].value;
    if(actualDuration){
      const durationValue = this.reportFormatService.getDurationValue(actualDuration);
      if(durationValue>0){
        this.formControl['duration'].setValue(durationValue);
      }else{
        this.formControl['duration'].reset();
      }
    }
  }

  addSymptom(){
    this.isSubmitted.set(true);
    if(this.symptomForm.valid){
      const formValue = this.symptomForm.value;
      const isDuplicateSymptom = this.selectedSymptoms.find(symptom=>symptom.parameter.id === formValue.parameter.id);
      if(isDuplicateSymptom){
        this.notificationService.notify("Duplicate symptom", MessageType.ERROR);
      } else {
        this.selectedSymptoms = [...this.selectedSymptoms, formValue];
      }
      this.resetForm();
    }
  }

  onSubmit(){
    const hasSelectedSymptoms = this.selectedSymptoms;
    if(hasSelectedSymptoms){
      this.dialogRef.close(this.selectedSymptoms);
      return;
    }
    this.dialogRef.close([]);
  }

  onTableAction(event:TableAction){
   if(event.type === 'delete'){
    this.selectedSymptoms.splice(event.index, 1);
    this.selectedSymptoms = [...this.selectedSymptoms];
   }
  }

  private resetForm(){
    this.symptomForm.reset();
    this.formControl['actualDuration'].setValue({period:DurationPeriod.DAY, value: ''});
    this.selectedSymptom = undefined;
    this.isSubmitted.set(false);
  }

}
