<div class="table-container">
  <table class="table " mat-table matSort [dataSource]="dataSource">
    <ng-container matColumnDef="sno">
        <th mat-header-cell *matHeaderCellDef> S.No </th>
        <td mat-cell *matCellDef="let row; let i = index"> {{i + (currentPage * perPage) + 1}} </td>
    </ng-container>

    <ng-container matColumnDef="symptomName">
        <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header> Symptom Name </th>
        <td class="text-center" mat-cell *matCellDef="let row">
          {{row.parameter.name}}
        </td>
    </ng-container>

    <ng-container matColumnDef="duration">
      <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header> Duration </th>
      <td class="text-center" mat-cell *matCellDef="let row">
        {{row.actualDuration.value}}  {{row.actualDuration.period | titlecase}}{{row.actualDuration.value > 1 ? 's':''}}
      </td>
  </ng-container>

    <ng-container matColumnDef="action" *ngIf="buttons.length">
        <th mat-header-cell *matHeaderCellDef> Action </th>
        <td mat-cell *matCellDef="let row;let i = index">
            <div class="flex gap-2 justify-c items-center">
                <div class="buttons-container">
                  <button *ngFor="let button of buttons"  type="button" mat-icon-button
                (click)="onClickAction(button.name, row, i)" [innerHTML]="button.iconUrl" [title]="button.name">
                </button>
                </div>
            </div>
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayColumns" class="bg-secondary"></tr>
    <tr mat-row *matRowDef="let row; columns: displayColumns;"
        [ngStyle]="{'backgroundColor': row.rowColor ? row.rowColor : 'inherit' }"></tr>
</table>
</div>
<div class="text-center font-medium my-3" *ngIf="!dataList?.length">
    No Data Found
</div>
