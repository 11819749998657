import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { DialogData } from '../../models/dialog.model';



@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule],
  //providers:[DialogService]
})
export class DialogComponent implements OnInit{

  htmlContent!: SafeHtml;

  private sanitizer= inject(DomSanitizer);
  constructor( @Inject(MAT_DIALOG_DATA) protected dialogData: DialogData){}

  ngOnInit(): void {
    const rawHTML = this.dialogData.content;
    this.htmlContent = this.sanitizer.bypassSecurityTrustHtml(rawHTML);
  }
}
