<app-grid-layout-template [backgroundImageUrl]="bgImageUrl">
  <div class="text-center text-white">
    <!-- <div>SIGN IN</div> -->
    <div class="text-4xl font-bold">Welcome!</div>
    <div class="font-light text-sm">Sign in to access your account.</div>
  </div>
  <div class="flex border border-b-1 border-[#5882C1]"></div>
  <form class="flex flex-col gap-2 mt-4 w-full mx-auto" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
    <div class="flex justify-center flex-col items-start relative pb-4">
      <app-text-input class="w-full text-white" label="Email Address" formControlName="userName" [hasError]="checkErrorMessage(loginForm, 'userName', isSubmitted())">
        <ng-container *ngIf="checkErrorMessage(loginForm, 'userName', isSubmitted())">
          <p *ngIf="formControl.userName.errors!['required']">
            Email Id required.
          </p>
          <p *ngIf="formControl.userName.errors!['pattern']">
            Invalid email id.
          </p>
        </ng-container>
      </app-text-input>
    </div>
    <div class="flex justify-center items-start flex-col relative pb-6">
      <app-text-input class="w-full text-white" label="Password" formControlName="password"
        [type]="textInputType.PASSWORD" [hasError]="checkErrorMessage(loginForm, 'password', isSubmitted())">
        <ng-container *ngIf="checkErrorMessage(loginForm, 'password', isSubmitted())">
          <p *ngIf="formControl.password.errors!['required']">
            Password required.
          </p>
        </ng-container>
      </app-text-input>
    </div>
    <button class="rounded w-full flex justify-center items-center py-2 font-medium bg-primary_dark">
      <div class="flex-1 text-center text-white">SIGN IN</div>
    </button>
    <footer>
      <div class="flex justify-center items-center my-1">
        <div class="flex-1 text-center text-white font-light text-xs">
          <a [routerLink]="'/auth/forgot-password'">Forgot your password?</a>
        </div>
      </div>
      <div class="flex justify-center items-center my-2">
        <div class="flex-1 text-center text-white font-light text-xs">Don't have an account? <a class="font-medium text-[14px]"
            [routerLink]="'/auth/sign-up'">Sign Up</a></div>
      </div>
      <div class="flex justify-center items-center mt-1">
        <asl-google-signin-button type='standard' size='medium' shape="rectangular"
          text="signin_with"></asl-google-signin-button>
      </div>

    </footer>

  </form>
</app-grid-layout-template>
