import { Injectable, signal } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { RequestPath } from 'src/app/core/constants/request-path.const';
import { ApiResponse } from 'src/app/core/interfaces/api-reponse.model';
import { BaseService } from 'src/app/core/services/base-service.class';
import { SignInResponse } from '../models/signin-response.model';
import { environment } from 'src/environments/environment';

@Injectable({providedIn: 'root'})
export class SocialAuthenticationService extends BaseService{

  private endPoint = signal(environment.AUTH_URL);


  async socialSignUp(payload:{token: string, provider: string}): Promise<ApiResponse<SignInResponse>>{
    return lastValueFrom(this.httpService.post(this.endPoint()+RequestPath.socialSignUp, payload));
  }

  async socialSignIn(payload:{token: string, provider: string}): Promise<ApiResponse<SignInResponse>>{
    return lastValueFrom(this.httpService.post(this.endPoint()+RequestPath.socialSignIn, payload));
  }
}
