<div class="py-4">
<h2 class="font-medium text-xl px-8">{{dialogData.title}}</h2>
<div class="my-4 dialog-body">
  <div class="px-8" [innerHTML]="htmlContent"></div>
</div>
<div align="end" class="px-8">
  <button mat-button mat-dialog-close *ngIf="dialogData.button.discard" class="font-medium mr-4">
    {{dialogData.button.discard}}
  </button>
  <button mat-button [mat-dialog-close]="true" [color]="dialogData.button.color" class="font-medium" cdkFocusInitial>
    {{dialogData.button.proceed}}</button>
</div>
</div>
