import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from 'src/app/auth/services/auth.service';

export const authGuard: CanActivateFn = async (route, state) => {
  const authService:AuthService = inject(AuthService);
  const router:Router = inject(Router);
  const isValidUser = await authService.verifyUserLogin();
  if(isValidUser){
    return true;
  } router.navigateByUrl("/auth/sign-in");
    return false;
};
