<app-page-title title="Upload Report"></app-page-title>
<div class="upload-report-container">
  <div class="flex items-center justify-center h-5/6">
    <div class="flex-col upload-box max-w-sm">
      <input multiple type="file" style="display: none" #fileUpload (change)="onFileSelected($event)" />
      <div class="border border-1 border-primary rounded-2xl px-8 py-6 w-full h-full"  *ngIf="!files.length; else fileView">
        <div class="h-full" fileDragDrop (filesChangeEmitter)="onFileChange($event)" (click)="fileUpload.click()">
          <div class="text-center font-medium text-secondary text-xl">
            Upload Your Report(s) Here!
          </div>
          <div class="text-center text-sm font-light text-gray-400">
            (jpg, jpeg, png or pdf)
           </div>
          <div class="flex justify-center items-center">
            <img src="/assets/images/upload-report.svg" >
          </div>
          <div class="text-center font-light text-sm text-secondary">
           <p> Drag and Drop your report here</p>
           <p>or</p>
           <button class="rounded w-full flex justify-center items-center my-2 py-2 font-medium bg-primary">
            <div class="flex-1 text-center text-white">Browse Files</div>
          </button>
          </div>
        </div>
      </div>
      <ng-template #fileView>
        <div class="px-8 py-6 w-full h-full">
          <div class="h-full">
            <div class="text-center font-medium text-secondary text-xl mb-4">
              Your Report
            </div>

            <div class="my-10">
              <ng-container *ngFor="let file of files; let i =index">
                <div class="flex justify-between gap-2 my-2">
                  <div class="truncate font-medium text-lg">{{file!.name}}</div>
                  <div>
                    <button class="remove-btn" (click)="removeFile(i)" *ngIf="!isSubmitted()" title="Remove file">
                      <img src="/assets/images/delete.svg" alt="Delete" class="h-6 w-6 delete-icon">
                    </button>
                    <button class="font-medium text-[green] success-btn"  *ngIf="progressValue()==100">Uploaded</button>
                  </div>
                </div>
              </ng-container>
            </div>

            <div>
              <ng-container *ngIf="!isSubmitted(); else upload">
                <button class="rounded w-full flex justify-center items-center mb-6 py-2 font-medium border border-primary hover:bg-blue-100" (click)="fileUpload.click()">
                  <div class="flex-1 text-center text-primary">Browse More Files</div>
                </button>
                <button class="rounded w-full flex justify-center items-center my-2 py-2 font-medium bg-primary" (click)="onUpload()">
                  <div class="flex-1 text-center text-white">Upload File</div>
                </button>
              </ng-container>
              <ng-template #upload>

                <ng-container *ngIf="!isResponseReceived; else responseReceived">
                  <div class="progress-container" *ngIf="progressValue()<100; else process">
                    <mat-progress-bar mode="determinate" [value]="progressValue()"></mat-progress-bar>
                  <label class="text-sm text-secondary">{{'Uploading your file ('+progressValue()+'%)'}}</label>
                  </div>
                  <ng-template #process>
                   <p class="mt-8 text-sm text-secondary">
                    Please wait, while we are processing your data..
                   </p>
                   <mat-progress-bar mode="indeterminate" class="mt-8"></mat-progress-bar>
                  </ng-template>
                </ng-container>
                <ng-template #responseReceived>
                  <p>Your response has been received!</p>
                </ng-template>
                <div class="mt-8 flex gap-4 items-center">
                  <label>Having symptoms?</label>
                  <button mat-flat-button color="primary" (click)="addSymptoms()">Add Now</button>
                 </div>
              </ng-template>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
