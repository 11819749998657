<div class="layout-container min-h-screen">
  <mat-drawer-container>
    <mat-drawer #drawer [mode]="innerWidth <=900 ? 'over' : 'side'" [opened]="innerWidth > 900">
      <app-sidebar class="no-print" [isFixed]="innerWidth > 900" (menuClick)="innerWidth <=900 ? drawer.close() : null"></app-sidebar>
    </mat-drawer>
    <div class="flex-1  mt-4 menu-content  min-h-screen">
      <app-header class="no-print" (menuClick)="drawer.toggle()"></app-header>
      <router-outlet></router-outlet>
    </div>
  </mat-drawer-container>
</div>
