import { Component } from '@angular/core';
import { BaseTableComponent } from 'src/app/core/components/base-table-component';
import { TableComponent } from 'src/app/shared/components/table/table.component';

@Component({
  selector: 'app-upload-symptom-table',
  templateUrl: './upload-symptom-table.component.html',
  styleUrls: ['./upload-symptom-table.component.scss']
})
export class UploadSymptomTableComponent extends TableComponent{

}
