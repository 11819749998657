import { Injectable } from "@angular/core";
import { ResultType } from "../constants/result-types.enum";
import { abnormalValue, normalValue, normalResultTypeValues, abnormalResultTypeValues } from "../constants/result-type.const";

@Injectable({
  providedIn: 'root'
})
export class ValueMapperService {

  getValueByMapperCode(value: number, resultType: ResultType) {
    const resultTypeArr = resultType.split("_")!;
    const ignoreType = resultType === ResultType.OTM || resultType === ResultType.MIN_MAX || typeof(value) !== "number";
    if (ignoreType) {
      return value;
    } else if (value === abnormalValue) {
      return resultTypeArr[0];
    } else if (value === normalValue) {
      return resultTypeArr[1];
    } else {
      return value;
    }
  }

  mapValueWithCode(resultType: ResultType, value:string){
    const testValue = (value ?? '').toString().toUpperCase();
    const ignoreType = resultType === ResultType.OTM || resultType === ResultType.MIN_MAX;
    if(ignoreType){
      return value;
    }else if(normalResultTypeValues.includes(testValue)){
      return normalValue;
    } else if(abnormalResultTypeValues.includes(testValue)){
      return abnormalValue;
    }else{
      return value
    }
  }

  getMappedValueFromCanBeMinmax(value:string, range:string, unit:string){
    const [min, max] = range.split('-');
    const isValueWithinRange = this.checkValuePresentInNormalRange(+value, +min, +max);
    if(isValueWithinRange) return normalValue;
    return abnormalValue;
  }

  checkValuePresentInNormalRange(value: number, min: number, max: number) {
    return +value >= +min && +value <= +max ? true : false;
  }

}
