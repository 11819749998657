<div class="sidebar flex flex-col bg-primary p-4 h-full text-white w-60 rounded-e-3xl"
  [ngClass]="[isFixed ? 'fixed' : 'sticky']">
  <div>
    <img src="/assets/images/logo.svg" alt="Logo" class="w-28 h-28 m-auto" [routerLink]="[ '/home']"
      (click)="menuClick.emit(true)">
  </div>
  <div class="flex flex-1 overflow-y-auto flex-col gap-2 mt-8 text-sm font-semibold items-start">

    <a [routerLink]="[ '/home']" routerLinkActive="active" class="px-4 py-2 flex gap-2 items-center"
      (click)="menuClick.emit(true)">
      <img src="/assets/images/home.svg" alt="" srcset=""> HOME</a>

    <a [routerLink]="[ '/upload-report']" routerLinkActive="active" class="px-4 py-2 flex gap-2 items-center"
      (click)="menuClick.emit(true)" [auth]="uiAccessList.UploadReport">
      <img src="/assets/images/upload-icon.svg" alt="" srcset=""> UPLOAD LAB REPORT</a>

    <a [routerLink]="[ '/insurance']" routerLinkActive="active" class="px-4 py-2 flex gap-2 items-center"
      (click)="menuClick.emit(true)" [auth]="uiAccessList.Insurance">
      <img class="w-8" src="/assets/images/insurance.svg" alt="" srcset="">INSURANCE
    </a>

    <a [routerLink]="[ '/document-scanner']" routerLinkActive="active" class="px-4 py-2 flex gap-2 items-center"
      (click)="menuClick.emit(true)" [auth]="uiAccessList.DocumentScanner">
      <img class="w-8" src="/assets/images/doc-scanner.svg" alt="" srcset="">UPLOAD MEDICAL RECORD
    </a>

    <a [routerLink]="[ '/report']" routerLinkActive="active" class="px-4 py-2 flex gap-2 items-center"
      (click)="menuClick.emit(true)">
      <img src="/assets/images/test.svg" alt="" srcset="">MANUAL ENTRY</a>

    <a [routerLink]="[ '/old-reports']" routerLinkActive="active" class="px-4 py-2 flex gap-2 items-center"
      (click)="menuClick.emit(true)" [auth]="uiAccessList.OldReports">
      <img src="/assets/images/old-record.svg" alt="" srcset="">OLD REPORTS</a>

    <a [routerLink]="[ '/laboratory']" routerLinkActive="active" class="px-4 py-2 flex gap-2 items-center"
      (click)="menuClick.emit(true)" [auth]="uiAccessList.Laboratory">
      <img src="/assets/images/lab.svg" alt="" srcset="">LABORATORY</a>

    <a [routerLink]="[ '/prescription']" routerLinkActive="active" class="px-4 py-2 flex gap-2 items-center"
      (click)="menuClick.emit(true)" [auth]="uiAccessList.Prescriptions">
      <img src="/assets/images/prescription.svg" alt="" srcset="">PRESCRIPTION</a>

    <a [routerLink]="[ '/chart']" routerLinkActive="active" class="px-4 py-2 flex gap-2 items-center"
      (click)="menuClick.emit(true)" [auth]="uiAccessList.Charts">
      <img src="/assets/images/chart.svg" alt="" srcset="">Charts</a>

    <a [routerLink]="[ '/vhs']" routerLinkActive="active" class="px-4 py-2 flex gap-2 items-center"
      (click)="menuClick.emit(true)" [auth]="uiAccessList.Vhs">
      <img class="w-8" src="/assets/images/vhs-predection.svg" alt="" srcset="">VHS LABORATORY</a>

    <a [routerLink]="[ '/comparison']" routerLinkActive="active" class="px-4 py-2 flex gap-2 items-center"
      (click)="menuClick.emit(true)" [auth]="[uiAccessList.ComparisonTable, uiAccessList.ReportComparison]">
      <img class="w-8" src="/assets/images/compare.svg" alt="" srcset="">Comparison</a>

  </div>
  <div class="footer-content">
    <div>You are using beta version <span style="opacity: 1;">😊</span></div>
    <div>&copy; 2023 Algorithm Health Pvt Ltd.</div>
  </div>
  <div class="close-btn">
    <button mat-icon-button (click)="menuClick.emit(true)">
      <img src="/assets/images/close.svg">
    </button>
  </div>
</div>
