import { CommonModule } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthModule } from 'src/app/auth/auth.module';
import { AuthService } from 'src/app/auth/services/auth.service';
import { BaseComponent } from 'src/app/core/components/base-component.class';
import { GridLayoutTemplateComponent } from 'src/app/layout/components/grid-layout-template/grid-layout-template.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { UserGuideComponent } from '../user-guide/user-guide.component';
import { MatIconModule } from '@angular/material/icon';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  standalone: true,
  imports: [CommonModule, GridLayoutTemplateComponent, RouterModule, AuthModule, SharedModule, UserGuideComponent, MatIconModule]
})
export class HomeComponent extends BaseComponent implements OnInit {

  private authService = inject(AuthService);
  bgImageUrl:string = '/assets/images/landing-bg.png';

  private dialog = inject(MatDialog);

  ngOnInit(): void {
    const hasDisplayedUserGuide = Boolean(localStorage.getItem('hasDisplayedUserGuide')) || false;
    if(!hasDisplayedUserGuide){
      this.openHelp();
    }

  }

  onLogOut(){
    this.authService.signOut();
  }

  openHelp(){
    const dialogRef = this.dialog.open(UserGuideComponent);
  }
}
