<div class="flex flex-col gap-1 relative">
<mat-form-field appearance="outline" class="w-full form-field-wrapper" [ngClass]="['form-field-wrapper', bgClass, hasError ? 'error-outline':'']">
  <mat-chip-grid #chipList aria-label="Chip Control selection">
    <mat-chip-row *ngFor="let data of selectedList; let i = index" (removed)="remove(i)">
      {{ data.label | titlecase}}
      <button matChipRemove>
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip-row>
    <input #chipInput  [matAutocomplete]="auto" [formControl]="chipFormControl" [placeholder]="placeholder"
      [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="add($event)" #trigger="matAutocompleteTrigger"  />
  </mat-chip-grid>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)"  >
    <mat-option *ngFor="let data of filteredList" [value]="data" (click)="trigger.openPanel()">
      {{ data.label | titlecase}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
<p class="text-red-600 text-xs absolute error-text">
  <ng-content #content></ng-content>
</p>
</div>
