import { CommonModule } from '@angular/common';
import { Component, HostListener } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterModule } from '@angular/router';
import { LayoutModule } from '../../layout.module';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  standalone: true,
  imports: [CommonModule, LayoutModule, RouterModule, MatSidenavModule],
})
export class LayoutComponent {
  innerWidth: number = window.innerWidth;
  isOpened = false;
  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.innerWidth = window.innerWidth;
  }
}
