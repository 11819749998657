import { Component, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseFormComponent } from 'src/app/core/components/base-form.class';
import { RoutePath } from 'src/app/core/constants/route.const';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent extends BaseFormComponent {

  private authService = inject(AuthService);

  forgotPasswordForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
  });

  get formControl() {
    return this.forgotPasswordForm.controls;
  }

  async onSubmit() {
    this.isSubmitted.set(true);
    if (this.forgotPasswordForm.valid) {
      const payload: { email: string } = this.forgotPasswordForm.value as {
        email: string;
      };
      const response = await this.authService.forgotPassword(payload);
      if (response) {
        this.router.navigateByUrl(RoutePath.verifyOtpExistingUser, {state:payload});
      }
    }
  }
}
